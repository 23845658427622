<ng-container [formGroup]="orderForm">
  <div class="form-row custom-form-row mt-0">
    <div class="col-12">
      <label>
        {{'Hoone' | translate}}
      </label>
      <div class="w-100">
        <ng-select formControlName="building"
                   class="custom w-100"
                   [items]="options$ | async"
                   [typeahead]="querySource"
                   bindLabel="text"
                   [clearable]="true"
                   [searchable]="true"
                   [placeholder]="'Otsi objekti' | translate"
                   [class.is-invalid]="formErrors.building">
        </ng-select>
        <small *ngIf="formErrors?.building?.required" class="invalid-feedback d-block">
          {{formErrors?.building?.required}}
        </small>
      </div>
    </div>
  </div>

  <div class="form-row custom-form-row mt-0" [hidden]="!orderForm.get('building').value">
    <div class="col-12">
      <label>
        {{'Haldur' | translate}}
      </label>
      <app-user-link [attr.title]="'Halduri info' | translate" [user]="propertyManager"></app-user-link>
    </div>
  </div>

  <div class="form-row custom-form-row mt-0">
    <div class="col-12">
      <label>{{'Asukoht' | translate}}</label>
      <div class="w-100">
        <input class="form-control"
               formControlName="location"
               [attr.placeholder]="'Asukoha täpsustus hoones' | translate"
               [class.is-invalid]="formErrors.location">
      </div>
    </div>
  </div>
</ng-container>
