import {Component} from '@angular/core';
import {ConfigService} from '@app/config/config.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SyncModalComponent} from '@app/core/components/admin/sync-modal/sync-modal.component';
import {AppNotificationModalComponent} from '@app/core/components/admin/app-notification-modal/app-notification-modal.component';
import {InstructionModalComponent} from '@app/order/instructions/instruction-modal.component';
import {ProjectManagerNotificationModalComponent} from '@app/core/components/admin/project-manager-notification-modal/project-manager-notification-modal.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  pushRightClass = 'sidenav-open';
  public logoPath = this.configService.get('logo');

  constructor(
    private configService: ConfigService,
    private modalService: BsModalService
  ) {}

  public getConfigUrl(name: string) {
    return this.configService.getCoreAppUrl(name, '/');
  }

  public isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  public toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  public openSyncModal() {
    this.modalService.show(SyncModalComponent);
    this.toggleSidebar();
  }

  public openNotificationModal() {
    this.modalService.show(AppNotificationModalComponent);
    this.toggleSidebar();
  }

  public openInstructionModal() {
    this.modalService.show(InstructionModalComponent);
  }

  public openSendProjectManagerNotificationModal(): void {
    this.modalService.show(ProjectManagerNotificationModalComponent);
  }
}
