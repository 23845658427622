import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormService} from "@app/order/form/form.service";

@Component({
  selector: '[form-requester-quest-section]',
  templateUrl: './requester-quest-section.component.html',
  styles: []
})
export class RequesterQuestSectionComponent {
  orderForm: FormGroup;
  @Input() formErrors: any;

  constructor(
    public formService: FormService
  ) {
    this.orderForm = this.formService.orderForm;
  }
}
