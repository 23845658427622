import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FileRef} from '@app/order/form/files-section/file-ref.model';
import {InstructionTypes} from '@app/order/instructions/instruction-types';

@Component({
    selector: 'app-instruction-row',
    templateUrl: './instruction-row.component.html',
})
export class InstructionRowComponent {

    @Input()
    public label: string;

    @Input()
    public type: string;

    @Input()
    public file: FileRef;

    @Input()
    public fileLink: string;

    @Input()
    public inputName: string;

    @Input()
    public form: FormGroup;

    @Input()
    public formName: string;

    @Input()
    public fileName: string;

    @Input()
    public name: string;

    @Input()
    public isEdit: boolean;

    @Input()
    public isNew: boolean;

    @Output()
    public updateInput: EventEmitter<any> = new EventEmitter<void>();

    public readonly InstructionTypes = InstructionTypes;
    public readonly RKAS_INSTRUCTIONS = this.InstructionTypes.RKAS_INSTRUCTIONS;
}
