<div class="modal-header">
    <h4 class="modal-title pull-left">Kasutusjuhendid</h4>
    <button type="button"
            class="close pull-right"
            aria-label="Sulge"
            (click)="close()">
        <span class="submenu-hover" aria-hidden="true"></span>
        <span class="icon icon_close" aria-hidden="true"></span>
    </button>
</div>

<div class="modal-body" [formGroup]="instructionForm">

    <app-instruction-row label="Klient"
                         formName="client"
                         [type]="CLIENT_INSTRUCTIONS"
                         [form]="instructionForm"
                         [name]="clientFile !== null ? clientFile.name : null"
                         [isEdit]="instructionForm.get('client').get('type').value === CLIENT_INSTRUCTIONS"
                         [isNew]="clientFile === null"
                         (updateInput)="updateInput(CLIENT_INSTRUCTIONS)">
    </app-instruction-row>

    <app-instruction-row label="Töövõtja"
                         formName="contractor"
                         [type]="CONTRACTOR_INSTRUCTIONS"
                         [form]="instructionForm"
                         [name]="contractorFile !== null ? contractorFile.name : null"
                         [isEdit]="instructionForm.get('contractor').get('type').value === CONTRACTOR_INSTRUCTIONS"
                         [isNew]="contractorFile === null"
                         (updateInput)="updateInput(CONTRACTOR_INSTRUCTIONS)">
    </app-instruction-row>

    <app-instruction-row label="Töövõtja EHM juhend"
                         [type]="CONTRACTOR_APPLIANCE_INSTRUCTIONS"
                         [form]="instructionForm"
                         formName="appliance"
                         [name]="applianceFile !== null ? applianceFile.name : null"
                         [isEdit]="instructionForm.get('appliance').get('type').value === CONTRACTOR_APPLIANCE_INSTRUCTIONS"
                         [isNew]="applianceFile === null"
                         (updateInput)="updateInput(CONTRACTOR_APPLIANCE_INSTRUCTIONS)">
    </app-instruction-row>

    <app-instruction-row label="RKAS"
                         formName="rkas"
                         inputName="fileLink"
                         [type]="RKAS_INSTRUCTIONS"
                         [form]="instructionForm"
                         [name]="rkasFileLink !== null ? rkasFileLink : null"
                         [isEdit]="instructionForm.get('rkas').get('type').value === RKAS_INSTRUCTIONS"
                         [isNew]="rkasFileLink === null"
                         (updateInput)="updateInput(RKAS_INSTRUCTIONS)">
    </app-instruction-row>

    <div class="modal-footer footer-padding">
        <button class="btn btn-outline-primary mr-4"
                type="button"
                (click)="close()">
            Katkesta
        </button>
        <button class="btn btn-primary"
                type="button"
                (click)="submit()">
            Salvesta
        </button>
    </div>

</div>
