<div class="sticky">
  <nav class="navbar navbar-details-page ">
    <app-create-quest-actions></app-create-quest-actions>
  </nav>
</div>


<div class="container mt-4 d-flex justify-content-center align-items-center flex-column"  [formGroup]="orderForm">
  <div class="alert alert-danger" role="alert" *ngIf="createService.showFormErrors">
    <button type="button" class="close" aria-label="Close" (click)="dismissErrors()">
      <span aria-hidden="true">&times;</span>
    </button>
    {{ 'Vormil esineb vigu!' | translate }}
  </div>
  <div class="alert alert-success text-center" role="alert" *ngIf="!createService.showFormErrors && createService.formSent">
    <h3>{{ 'Taotlus esitatud!' | translate }}</h3><br>{{ 'Uue probleemi teavitamiseks' | translate }} <a href="javascript:void(0)" (click)="createService.reset()">{{ 'vajuta siia' | translate }}</a>!
  </div>

  <div *ngIf="!createService.formSent">
    <div class="block block-white">
      <h5 class="block-title title-border-bottom">{{ 'Detailid' | translate }}</h5>
      <div class="p-4 p-md-4 p-lg-5">
        <div >
          <section class="box-typical" public-building-section [formErrors]="createService.formErrors"></section>
          <div *ngIf="!(createService.isMinistryPage$ | async)">
            <hr  />
            <section class="box-typical"  form-requester-quest-section [formErrors]="createService.formErrors"></section>
          </div>
        </div>
      </div>
    </div>

    <div class="block block-white mt-4 mb-4">
      <h5 class="block-title title-border-bottom">{{ 'Probleem' | translate }}</h5>

      <div class="p-4 p-md-4 p-lg-5">
        <div class="form-row custom-form-row">
          <div class="col-12">
            <label>{{ 'Probleem' | translate }}</label>
            <textarea formControlName="description" [class.is-invalid]="createService.formErrors.description" class="form-control" id="building" rows="4"></textarea>
          </div>
          <small *ngIf="createService.formErrors?.description?.required" class="invalid-feedback d-block">{{createService.formErrors.description?.required}}</small>
        </div>
        <form-files-quest-section [orderFileType]="'ORFI_ORDER'"></form-files-quest-section>
      </div>
    </div>
  </div>
</div>
