import {Injectable} from '@angular/core';
import {ContractorPersonResource} from '@app/core/resource/contractor-person.resource';

@Injectable()
export class ContractorPersonAssignService {

  public constructor(private contractPersonRes: ContractorPersonResource) { }

  public assign(contractId: number, contractObjectId: number, coosId: number, userIds: number[], roleType: string): Promise<{}> {
    return this.contractPersonRes.put({contractId, contractObjectId, coosId, userIds, roleType});
  }

  public addBulk(contractIds: number[], contractObjectIds: number[], coosIds: number[], userIds: number[], roleType: string): Promise<{}> {
    return this.contractPersonRes.post({contractIds, contractObjectIds, coosIds, userIds, roleType});
  }
}
