import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResizeService implements OnDestroy {

  private readonly ngDestroy = new Subject<void>();
  public readonly screenWidth$: BehaviorSubject<number> = new BehaviorSubject(null);
  public readonly isMobileView$: Observable<boolean> = this.screenWidth$.pipe(map(width => width <= 1024));

  constructor() {
    this.onInit();
  }

  onInit() {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.ngDestroy)
      ).subscribe((evt: any) => {
      this.setScreenWidth(evt.target.innerWidth);
    });
  }

  private setScreenWidth(width: number): void {
    this.screenWidth$.next(width);
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
