import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  IResourceMethodStrict,
  ResourceAction,
  ResourceHandler,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from '@app/core/services/auth.service';
import {OrderDto} from '../resource-dto/order';
import {InspectionDto} from '../resource-dto/inspection';
import {PropertyRatesDto} from '@app/core/resource-dto/property-rates';
import {OrderCostsDto} from '@app/core/resource-dto/order-cost';
import {OrderCostOptionsDto} from '@app/core/resource-dto/order-cost-option';
import {OrderFileDto} from '@app/core/resource-dto/order-file';
import {OrderCommentDto} from '@app/core/resource-dto/order-comment';
import {OrderRepairParameters} from '@app/core/resource-dto/order-repair/order-repair.parameters';
import {OrderRepairPageResponse} from '@app/core/resource-dto/order-repair/order-repair-page.response';
import {OrderRepairRequest} from '@app/core/resource-dto/order-repair/order-repair.request';
import ArchivedOrderResponse = OrderDto.ArchivedOrderResponse;
import OrderQuotationAcceptResponse = OrderDto.OrderQuotationAcceptResponse;
import ProblemsOutput = InspectionDto.ProblemsOutput;

@Injectable({providedIn: 'root'})
export class OrderRes extends AuthGuardedResource {

  constructor(
      protected requestHandler: ResourceHandler,
      protected authService: AuthService,
      protected config: ConfigService
  ) {
      super(requestHandler, authService, config);

      this.$setUrl(config.getBackendUrl('/orders'));
  }

  @ResourceAction({expectJsonArray: false})
  query: IResourceMethod<OrderDto.QueryInput, OrderDto.QueryOutput>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/stats',
    method: ResourceRequestMethod.Get
  })
  queryStats: IResourceMethod<OrderDto.QueryStatsInput, OrderDto.QueryStatsOutput>;

  @ResourceAction({
    expectJsonArray: true,
    path: '/stats/warranty',
    method: ResourceRequestMethod.Get
  })
  queryWarrantyStats: IResourceMethod<OrderDto.QueryStatsInput, OrderDto.QueryWarrantyStatsOutput>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/tenants',
    method: ResourceRequestMethod.Get
  })
  queryTenants: IResourceMethod<PropertyRatesDto.QueryInput, PropertyRatesDto.QueryOutput>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/{!orderId}/files',
    method: ResourceRequestMethod.Get
  })
  queryFiles: IResourceMethod<{orderId: number}, OrderFileDto.QueryOutput>;

  @ResourceAction({path: '/{!orderId}/repairs'})
  queryOrderRepairs: IResourceMethodStrict<null, OrderRepairParameters, {orderId: number}, OrderRepairPageResponse>;

  @ResourceAction({
    path: '/{!orderId}/repairs',
    method: ResourceRequestMethod.Put
  })
  updateOrderRepairs: IResourceMethodStrict<OrderRepairRequest, null, {orderId: number}, void>;

  @ResourceAction({
    path: '/{!orderId}'
  })
  get: IResourceMethod<OrderDto.GetOrderInput, OrderDto.Order>;

  @ResourceAction({method: ResourceRequestMethod.Post})
  save: IResourceMethodStrict<OrderDto.OrderInput, any, void, OrderDto.Order>;

  @ResourceAction({
    path: '/{:orderId}/comments',
    method: ResourceRequestMethod.Post
  })
  saveComment: IResourceMethodStrict<OrderDto.CommentInput, any, {orderId: number}, OrderDto.Comment>;

  @ResourceAction({
    path: '/{:orderId}/inspection-notes',
    method: ResourceRequestMethod.Put
  })
  saveInspectionNotes: IResourceMethodStrict<OrderDto.InspectionNoteInput, any, {orderId: number}, ProblemsOutput>;

  @ResourceAction({path: '/{!orderId}/comments'})
  queryComments: IResourceMethodStrict<{orderId: number}, any, any, OrderCommentDto.OrderCommentsOutput>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!orderId}'
  })
  update: IResourceMethodStrict<OrderDto.OrderInput, any, {orderId: number}, OrderDto.Order>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!orderId}/event'
  })
  updateEvent: IResourceMethodStrict<OrderDto.EventInput, any, {orderId: number}, OrderDto.Status>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!orderId}/tenants'
  })
  saveTenants: IResourceMethodStrict<OrderDto.TenantsInput, any, {orderId: number}, OrderDto.OrderTenant[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!orderId}/assignee',
  })
  updateAssignee: IResourceMethodStrict<OrderDto.AssigneeInput, any, {orderId: number}, OrderDto.Assignee>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!orderId}/satisfaction',
  })
  updateSatisfaction: IResourceMethodStrict<OrderDto.SatisfactionInput, any, {orderId: number}, OrderDto.Satisfaction>;

  @ResourceAction({path: '/{!orderId}/costs'})
  getCosts: IResourceMethodStrict<{orderId: number}, any, any, OrderCostsDto.CostsOutput>;

  @ResourceAction({path: '/{!orderId}/cost-options'})
  getCostOptions: IResourceMethodStrict<{orderId: number}, any, any, OrderCostOptionsDto.CostOptionsOutput>;

  @ResourceAction({
    path: '/{!orderId}/costs',
    method: ResourceRequestMethod.Post,
  })
  saveCost: IResourceMethodStrict<OrderCostsDto.CostInput, any, {orderId: number}, OrderCostsDto.CostsOutput>;

  @ResourceAction({
    path: '/{!orderId}/costs/{!costId}',
    method: ResourceRequestMethod.Put,
  })
  updateCost: IResourceMethodStrict<OrderCostsDto.CostInput, any, {orderId: number, costId: number}, OrderCostsDto.CostsOutput>;

  @ResourceAction({
    path: '/{!orderId}/costs/{!costId}',
    method: ResourceRequestMethod.Delete,
  })
  deleteCost: IResourceMethodStrict<{orderId: number, costId: number}, any, any, void>;

  @ResourceAction({
    path: '/{!orderId}/costs',
    method: ResourceRequestMethod.Put,
  })
  updateCosts: IResourceMethodStrict<OrderCostsDto.CostsInput, any, {orderId: number}, OrderCostsDto.CostsOutput>;

  @ResourceAction({
    path: '/{!orderId}/files/{!orderFileId}',
    method: ResourceRequestMethod.Get,
  })
  getFile: IResourceMethodStrict<{orderId: number, orderFileId: number}, any, any, File|Blob>;

  @ResourceAction({
    path: '/{!orderId}/files',
    method: ResourceRequestMethod.Post,
  })
  saveFile: IResourceMethodStrict<OrderFileDto.FileInput, any, {orderId: number}, OrderFileDto.File>;

  @ResourceAction({
    path: '/{!orderId}/files/{!orderFileId}',
    method: ResourceRequestMethod.Put,
  })
  updateFile: IResourceMethodStrict<OrderFileDto.FileInput, any, {orderId: number, orderFileId: number}, OrderFileDto.File>;

  @ResourceAction({
    path: '/{!orderId}/files/{!orderFileId}',
    method: ResourceRequestMethod.Delete,
  })
  deleteFile: IResourceMethodStrict<{orderId: number, orderFileId: number}, any, any, void>;

  @ResourceAction({
    path: '/{!orderId}/assignees',
    expectJsonArray: false
  })
  queryAssignees: IResourceMethodStrict<{orderId: number}, any, any, OrderDto.AssigneesQueryOutput>;

  @ResourceAction({
    path: '/{!orderId}/building',
    method: ResourceRequestMethod.Put
  })
  updateBuilding: IResourceMethodStrict<OrderDto.OrderBuildingInput, any, any, OrderDto.Building>;

  @ResourceAction({
    path: '/{contractId}/archive',
    method: ResourceRequestMethod.Put
  })
  archiveByContract: IResourceMethod<{contractId: number}, ArchivedOrderResponse[]>;

  @ResourceAction({
    path: '/{orderId}/quotationDueDateTime',
    method: ResourceRequestMethod.Put
  })
  updateQuotationDueDate: IResourceMethodStrict<OrderDto.OrderQuotationDueDateRequest, any, {orderId: number}, void>;

  @ResourceAction({
    path: '/{orderId}/quotations',
    method: ResourceRequestMethod.Put
  })
  addQuotations: IResourceMethodStrict<OrderDto.OrderQuotationPutRequest, any, {orderId: number}, void>;

  @ResourceAction({
    path: '/{orderId}/quotations/accept',
    method: ResourceRequestMethod.Put
  })
  acceptQuotation: IResourceMethodStrict<OrderDto.OrderQuotationAcceptRequest, any, {orderId: number}, OrderQuotationAcceptResponse>;

  @ResourceAction({
    path: '/{orderId}/quotations/approve',
    method: ResourceRequestMethod.Put
  })
  approveQuotation: IResourceMethodStrict<{orderId: number}, any, any, void>;

  @ResourceAction({
    path: '/{orderId}/offer/addComment',
    method: ResourceRequestMethod.Put
  })
  addOfferComment: IResourceMethodStrict<OrderDto.OrderQuotationOfferCommentRequest, any, {orderId: number}, void>;

  @ResourceAction({
    path: '/{orderId}/appliance-maintenances',
    method: ResourceRequestMethod.Put
  })
  addApplianceMaintenances: IResourceMethodStrict<OrderDto.OrderApplianceMaintenancesRequest, any, {orderId: number}, void>;

  @ResourceAction({
    path: '/{orderId}/verify-work',
    method: ResourceRequestMethod.Put
  })
  verifyWork: IResourceMethodStrict<OrderDto.OrderVerifyWorkRequest, any, {orderId: number}, void>;

  @ResourceAction({
    path: '/{orderId}/order-viewer',
    method: ResourceRequestMethod.Put
  })
  setOrderViewer: IResourceMethodStrict<OrderDto.OrderViewerRequest, any, {orderId: number}, any>;
}
