export class GpsCoordinatesModel {

  public readonly latitude: number;
  public readonly longitude: number;

  private constructor(coordinates: Coordinates) {
    this.latitude = coordinates.latitude;
    this.longitude = coordinates.longitude;
  }

  public static from(coordinates?: Coordinates | null): GpsCoordinatesModel | undefined {
    if (!coordinates) return undefined;
    if ([null, undefined].includes(coordinates.latitude)) return undefined;
    if ([null, undefined].includes(coordinates.longitude)) return undefined;

    return new GpsCoordinatesModel(coordinates);
  }
}
