import {Component} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {FileRef} from '../files-section/file-ref.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {ConfigService} from '@app/config/config.service';
import {FileRes} from '@app/core/resource/file.resource';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FileSectionAbstract} from '@app/order/form/files-quest-section/file-section.abstract';
import {CreateOfferService} from '@app/order/create-offer/create-offer.service';

@Component({
  selector: 'form-files-quotation-section',
  templateUrl: './files-quest-section.component.html',
  styleUrls: ['./files-quest-section.component.scss']
})
export class FormQuotationFilesSectionComponent extends FileSectionAbstract {

  filesArray: FormArray = this.offerService.filesArray;
  form: FormGroup;

  public constructor(
    protected config: ConfigService,
    protected fileRes: FileRes,
    protected modalService: BsModalService,
    protected toastr: ToastrService,
    protected offerService: CreateOfferService
  ) {
    super(config, fileRes, modalService, toastr);
    this.form = this.offerService.quotationForm;

    this.filesArray.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      value.forEach((value: any) => {
        let fileRef = value.fileRef;
        fileRef.isUploaded = true;
        fileRef.uploadProgress = 1;
        fileRef.name = value.fileRef.file.name;
      });
    });
  }

  protected removeFileControl(fileControl: FormGroup) {
    this.offerService.removeFileControl(fileControl)
  }

  protected addFileControl(fileRef: FileRef) {
    this.offerService.addFileControl(fileRef)
  }
}
