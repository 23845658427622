import {Injectable} from '@angular/core';
import {forkJoin, Observable, Observer} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {OrderFileDto} from '@app/core/resource-dto/order-file';
import {OrderRes} from '@app/core/resource/order.resource';
import {FileService} from '../edit/file.service';
import {ManageService} from '../manage.service';

@Injectable()
export class TempFileService extends FileService {

    private nextId = 1;
    protected readonly files: OrderFileDto.FileInput[] = [];

    constructor(
      manageService: ManageService,
      orderRes: OrderRes
    ) {
      super(manageService, orderRes);
    }

    public saveFile(input: OrderFileDto.FileInput): Promise<OrderFileDto.File> {
      return new Promise<OrderFileDto.File>((resolve, _) => {
        const file: OrderFileDto.File = new OrderFileDto.File();
        if (input.id) {
          file.id = input.id;
        } else {
          file.id = this.nextId++;
          this.files.push(file);
        }
        file.fileId = input.fileId;
        file.description = input.description;
        file.orderFileType = input.orderFileType;

        resolve(file);
      });
    }

    public deleteFile(id: number): Promise<void> {
      if (id > 0 && this.files.length >= id) {
        this.files[id - 1] = null;
      }

      return new Promise<void>((resolve, _) => resolve());
    }

    public saveOrderFiles(orderId: number): Observable<OrderFileDto.File[]> {
      if (this.files.length) {
        const observables: Observable<OrderFileDto.File>[] = [];
        this.files.forEach((input: OrderFileDto.FileInput) => {
          if (input) { // Skip "deleted" elements
            observables.push(fromPromise(this.orderRes.saveFile(input, null, {orderId})));
          }
        });

        return forkJoin(observables);
      }

      return new Observable((observer: Observer<OrderFileDto.File[]>) => {
        observer.next([]);
        observer.complete();
      });
    }
}
