import {Injectable} from '@angular/core';
import {IResourceMethodStrict, ResourceAction, ResourceParams, ResourceRequestMethod, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from "@app/core/services/auth.service";

@Injectable({providedIn: 'root'})
@ResourceParams({withCredentials: true})
export class AppNotificationResource extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/app-notifications'));
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get
    })
    get: IResourceMethodStrict<any, any, any, {}>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    post: IResourceMethodStrict<{}, {}, void, {}>;

    @ResourceAction({
        path: '/{!appNotificationId}',
        method: ResourceRequestMethod.Delete,
    })
    delete: IResourceMethodStrict<{appNotificationId: number}, any, any, void>;
}
