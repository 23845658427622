import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import {UserregistrySyncResource} from "@app/core/resource/userregistry-sync.resource";

@Component({
  selector: 'app-userregistry-sync',
  templateUrl: './userregistry-sync.component.html'
})
export class UserregistrySyncComponent {

  public readonly form: FormGroup = new FormGroup({
    updatedAfter: new FormControl(moment(), [Validators.required])
  });
  public readonly validationMessages = {
    updatedAfter: {
      required: 'Palun sisesta alguskuupäev'
    }
  };
  public showFormErrors = false;
  public progress = false;

  constructor(
    private userregistrySyncRes: UserregistrySyncResource,
    private toastr: ToastrService
  ) { }

  public isInvalid(field: string): boolean {
    const control = this.form.get(field);

    return control.invalid && (this.showFormErrors);
  }

  public submit(): void {
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});

    if (this.form.valid) {
        this.progress = true;
        const input: any = this.prepareInputDtoForSubmit();

        this.userregistrySyncRes.userregistrySync({}, input, null).then(
          () => this.toastr.success('Kasutajate uuendamine on käivitatud!'),
          error => this.toastr.error(JSON.stringify(error), 'Viga!')
        ).finally(() => this.progress = false);
    }
  }

  private prepareInputDtoForSubmit(): any {
    const formModel = this.form.value;
    const input: any = {};

    if (formModel.updatedAfter) {
      input.updatedAfter = moment(formModel.updatedAfter, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD[T]HH:mm:ss');
    }

    return input;
  }
}
