import {Component} from "@angular/core";
import {CreateQuestService} from "@app/order/create-quest/create-quest.service";

@Component({
  selector: 'app-create-quest-actions',
  templateUrl: './create-quest-actions.component.html',
})
export class CreateQuestActionsComponent {
  public actions$ = this.createService.actions$;

  constructor (
    public createService: CreateQuestService
  ) { }

  save() {
    this.createService.save().then(result => {});
  }
}
