import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {InstructionDto} from '@app/core/resource-dto/instruction';
import {InstructionResource} from '@app/core/resource/instruction.resource';
import {ConfigService} from '@app/config';
import {InstructionTypes} from '@app/order/instructions/instruction-types';

@Component({templateUrl: 'instruction-modal.component.html'})
export class InstructionModalComponent implements OnDestroy {

    public constructor(
        private instructionRes: InstructionResource,
        private config: ConfigService,
        private modalRef: BsModalRef,
    ) {
        this.loadInstructions();
    }

    public instructions: InstructionDto.Instruction[] = [];
    private readonly ngDestroy: Subject<void> = new Subject<void>();

    protected readonly InstructionTypes = InstructionTypes;

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public content(type: InstructionTypes): {label: string, permission: string} | null {
        switch (type) {
            case InstructionTypes.CLIENT_INSTRUCTIONS:
                return {
                    label: 'Klient',
                    permission: 'instructions.access.client-instructions'
                };
            case InstructionTypes.CONTRACTOR_INSTRUCTIONS:
                return {
                    label: 'Töövõtja',
                    permission: 'instructions.access.contractor-instructions'
                };
            case InstructionTypes.CONTRACTOR_APPLIANCE_INSTRUCTIONS:
                return {
                    label: 'Töövõtja - EHM juhend',
                    permission: 'instructions.access.contractor-appliance-instructions'
                };
            case InstructionTypes.RKAS_INSTRUCTIONS:
                return {
                    label: 'RKAS',
                    permission: 'instructions.access.rkas-instructions'
                };
            default:
                console.error('Invalid instruction type: ' + type);
                return null;
        }
    }

    public downloadFile(id: number): void {
        window.location.href = this.config.getBackendUrl('/files/' + id + '/download');
    }

    public close(): void {
        this.modalRef.hide();
    }

    private loadInstructions(): void {
        this.instructionRes.getAll().then(instructions => {
            this.instructions = instructions;
        });
    }
}
