export {};

declare global {
  interface String {
    includesAll(args: string[]): boolean;
  }
}

String.prototype.includesAll = function(args: string[]): boolean {
  return args.every(this.includes.bind(this));
};
