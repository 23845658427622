import {Injectable, OnDestroy} from '@angular/core';
import {PublicQuotationRes} from '@app/core/resource/public-quotation.resource';
import {ReplaySubject, Subject} from 'rxjs';
import {QuotationDto} from '@app/core/resource-dto/quotation';
import {Router} from '@angular/router';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {FileRef} from '@app/order/form/files-section/file-ref.model';
import {takeUntil} from 'rxjs/operators';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {HttpEventType} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class CreateOfferService implements OnDestroy {

  public readonly filesArray: FormArray = new FormArray([], Validators.required);
  public readonly quotationSource = new ReplaySubject<QuotationDto.PublicQuotation>(1);
  public readonly quotationForm: FormGroup = new FormGroup({
    companyName: new FormControl(false, Validators.required),
    companyEmail: new FormControl('', [Validators.required, Validators.email]),
    clientComment: new FormControl(),
    sum: new FormControl('', [Validators.required, Validators.min(0.01)]),
    hourlyRate: new FormControl('', Validators.required),
    materialCost: new FormControl('', Validators.required)
  });

  public showFormErrors = false;
  public readonly formErrors = {
    companyName: null as any,
    companyEmail: null as any,
    sum: null as any,
    files: null as any,
    hourlyRate: null as any,
    materialCost: null as any
  };

  public offerSent = false;
  public progressSource = new ReplaySubject<boolean>(1);

  private ngDestroy: Subject<void> = new Subject<void>();
  private readonly validationMessages = {
    companyName: {
      required: 'Palun sisesta ettevõtte nimi.',
    },
    companyEmail: {
      required: 'Palun sisesta e-posti aadress.',
      email: 'Palun sisesta korrektne e-posti aadress.'
    },
    sum: {
      required: 'Palun sisesta korrektne maksumus',
      min: 'Palun sisesta korrektne maksumus'
    },
    hourlyRate: {
      required: 'Palun sisesta tunnihind'
    },
    materialCost: {
      required: 'Palun sisesta materjali hind'
    }
  };

  private quotationToken: string;
  private progress = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private quotationRes: PublicQuotationRes,
    private reCaptchaV3Service: ReCaptchaV3Service,
  ) {

    this.quotationForm.valueChanges
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((_: any) => this.onFormValueChanged());

    this.progressSource.pipe(takeUntil(this.ngDestroy)).subscribe(progress => {
      this.progress = progress;
    });
  }

  public loadOffer(token: string) {
    this.quotationRes.query({token}).then(quotation => {
      this.quotationToken = token;
      this.quotationForm.patchValue({
        companyName: quotation.companyName,
        companyEmail: quotation.recipientEmail
      });
      this.quotationSource.next(quotation);
    }).catch(error => {
      if (error.status === 403 && error.body.errorCode === 'quotation_offer_expired') {
        this.expiredRedirect();
      } else {
        this.accessDeniedRedirect();
      }
    });
  }

  public accessDeniedRedirect() {
    this.router.navigate(['volitus-puudub']);
  }

  public expiredRedirect() {
    this.router.navigate(['aegunud']);
  }

  public addFileControl(fileRef: FileRef) {
    const fileControl: FormGroup = new FormGroup({
      fileRef: new FormControl(fileRef, [Validators.required]),
    });

    this.filesArray.push(fileControl);
    return fileControl;
  }

  public removeFileControl(fileControl: FormGroup): void {
    const index: number = this.filesArray.controls.indexOf(fileControl);

    if (index >= 0) {
      this.filesArray.removeAt(index);
    }
  }

  public save() {
    const that = this;
    return new Promise((resolve, _) => {
      if (that.progress) return resolve(false);
      if (that.validateForm()) {
        that.progressSource.next(true);
        that.reCaptchaV3Service.execute('savePublicQuotation').subscribe(token => {
          const quotation = that.prepareInputDtoForSubmit(token, that.quotationToken);
          that.quotationRes.saveQuotation(quotation).subscribe(
            (event) => {
              if (event.type === HttpEventType.Response && event.status === 201) {
                that.offerSent = true;
                that.progressSource.next(false);
              }
            }, (error) => {
              that.toastr.error(JSON.stringify(error), 'Viga!');
              that.progressSource.next(false);
              resolve(error);
            });
          return resolve(true);
        });
      } else {
        that.progressSource.next(false);
        resolve('Vigane vorm');
      }
    });
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private prepareInputDtoForSubmit(token: string, quotationToken: string) {
    const quotation = new FormData();
    quotation.append('token', token);
    quotation.append('quotationToken', quotationToken);
    quotation.append('companyName', this.quotationForm.value.companyName);
    quotation.append('recipientEmail', this.quotationForm.value.companyEmail);
    quotation.append('sum', this.quotationForm.value.sum);
    quotation.append('hourlyRate', this.quotationForm.value.hourlyRate);
    quotation.append('materialCost', this.quotationForm.value.materialCost);
    if (this.quotationForm.value.clientComment) {
      quotation.append('clientComment', this.quotationForm.value.clientComment);
    }

    this.filesArray.value.forEach(fileRef => {
      quotation.append('files', fileRef.fileRef.file);
    });

    return quotation;
  }

  private validateForm(): boolean {
    this.quotationForm.markAsTouched({onlySelf: true});
    this.quotationForm.markAsDirty({onlySelf: true});
    Object.keys(this.quotationForm.controls).map((controlName) => {
      this.quotationForm.get(controlName).markAsTouched({onlySelf: true});
      this.quotationForm.get(controlName).markAsDirty({onlySelf: true});
    });
    this.quotationForm.updateValueAndValidity({onlySelf: false});
    this.filesArray.markAsTouched();

    if (this.quotationForm.valid && this.filesArray.valid) {
      return true;
    } else {
      this.showFormErrors = true;
      window.scrollTo(0, 0);

      return false;
    }
  }

  private onFormValueChanged() {
    if (!this.quotationForm) return;
    const form = this.quotationForm;

    for (const field in this.formErrors) {
      this.formErrors[field] = null;
      const control = form.get(field);

      if (control && (control.dirty) && !control.valid) {
        const messages = this.validationMessages[field];

        this.formErrors[field] = control.errors;

        for (const key in control.errors) {
          if (this.formErrors[field][key]) {
            this.formErrors[field][key] = messages[key];
          }
        }
      }
    }
    if (!this.filesArray || this.filesArray.length === 0) {
      this.formErrors.files = '';
    }

    if (this.quotationForm.valid && this.filesArray?.length > 0) {
      this.showFormErrors = false;
    }
  }
}
