import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, ReplaySubject, Subject, Observable, of, BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';
import {BuildingDto} from '@app/core/resource-dto/building';
import {BuildingRes} from '@app/core/resource/building.resource';
import {PermissionsService} from '@app/shared/services/permissions.service';
import {ConfigService} from '@app/config/config.service';
import {PublicBuildingRes} from '@app/core/resource/public-building.resource';
import {FormControl} from '@angular/forms';
import QueryInput = BuildingDto.QueryInput;
import QueryOutput = BuildingDto.QueryOutput;
import Building = BuildingDto.Building;

@Injectable()
export class BuildingAdapter implements OnDestroy {

  private static readonly publicPages = ['teata-probleemist', 'report-a-problem'];
  private readonly ngDestroy: Subject<void> = new Subject<void>();
  private readonly buildingsMapSource = new BehaviorSubject<Map<number, Building>>(new Map<number, Building>());
  private readonly buildingAdapterCallerSource = new ReplaySubject<string>(1);
  private readonly optionsSource = new BehaviorSubject<any[]>([]);
  private readonly buildingsMap$ = this.buildingsMapSource.asObservable();
  public readonly myBuildingsFilter = new FormControl(true);
  public readonly querySource = new BehaviorSubject<string>('');
  public readonly options$ = this.optionsSource.asObservable();
  public readonly buildingAdapterCaller$ = this.buildingAdapterCallerSource.asObservable();
  private readonly typeahead$ = combineLatest([
    this.permissionsService.permissions$.pipe(distinctUntilChanged()),
    this.querySource.asObservable(),
    this.buildingAdapterCaller$,
    this.myBuildingsFilter.valueChanges.pipe(startWith(this.myBuildingsFilter.value === true))
  ]).pipe(takeUntil(this.ngDestroy), debounceTime(200), switchMap(this.mapTypeahead.bind(this)));

  private permissions: any = {};

  static getBuildingOption(building: Building, showBuildingCode: boolean): any {
    return building ? {
      id: building.id,
      text: building.address + ' - ' + building.name + (showBuildingCode ? ' - ' + building.code : ''),
      code: building.code,
      address: building.address,
      name: building.name,
      propertyCode: building.property ? building.property.code : null,
      users: building.users.map(bu => bu.id),
      ownerSupervisorUsers: building.ownerSupervisorUsers.map(bu => bu.id),
      projectManagers: building.projectManagers.map(u => u.id),
      managerId: building.manager.id
    } : null;
  }

  constructor(
    private buildingRes: BuildingRes,
    private publicBuildingRes: PublicBuildingRes,
    private permissionsService: PermissionsService,
    private config: ConfigService
  ) {
    this.listenToPermissions();
    this.listenToTypeahead();
  }

  public ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public queryAllBuildings(): void {
    if (!!this.permissions['access.records-prison-tartu']) {
      const query: QueryInput = {
        query: '',
        propertyFilter: true
      };
      this.buildingRes.query(query).then(response => this.setBuildings(response.buildings));
    } else {
      this.setOptions([]);
    }
  }

  public queryPropertyBuildings(propertyCode: string, myBuildings?: boolean): void {
    const query: QueryInput = {
      query: '',
      propertyCode
    };
    if (!!myBuildings) {
      query.myBuildings = myBuildings;
    }

    this.buildingRes.query(query).then(response => this.setBuildings(response.buildings));
  }

  public getBuilding(id: number | undefined): Observable<Building> {
    return this.buildingsMap$.pipe(map((buildingsMap: Map<number, Building>) => buildingsMap.get(+id)));
  }

  public setBuildingAdapterCallerSource(source: string): void {
    this.buildingAdapterCallerSource.next(source);
  }

  public setOptions(options: any[]): void {
    this.optionsSource.next(options);
  }

  private listenToPermissions(): void {
    this.permissionsService.permissions$.pipe(distinctUntilChanged(), takeUntil(this.ngDestroy)).subscribe(p => this.permissions = p);
  }

  private listenToTypeahead(): void {
    this.typeahead$.subscribe(output => {
      if (!output) return;

      this.setBuildings(output.buildings);
    });
  }

  private mapTypeahead([permissions, term, source, myBuildings]: [any, string, string, boolean]): Observable<QueryOutput> {
    const query: QueryInput = {query: term};

    if (!this.isPublic() && this.hasMyBuildingsPermission(permissions)) {
      query.myBuildings = myBuildings;
    }

    if (source === 'inspection') {
      const regionTypes: string[] = [];

      Object.keys(permissions).forEach(key => {
        if (key.includes('.access.records-north')) {
          regionTypes.push('REGI_NORTH');
        } else if (key.includes('.access.records-south')) {
          regionTypes.push('REGI_SOUTH');
        } else if (key.includes('.access.records-east')) {
          regionTypes.push('REGI_EAST');
        } else if (key.includes('.access.records-west')) {
          regionTypes.push('REGI_WEST');
        }
      });

      if (regionTypes.length > 0) {
        query.regionTypes = regionTypes;
      }
    } else if (source === 'order' && permissions['access.records-prison-tartu']) {
      query.propertyFilter = true;
    }

    return (!!term?.length || query.propertyFilter) ? this.fromQuery(query) : of(null);
  }

  private hasMyBuildingsPermission(permissions: any): boolean {
    return !!permissions['building.access.my-buildings.manager-buildings'] ||
           !!permissions['building.access.user-buildings'];
  }

  private fromQuery(query: QueryInput): Observable<QueryOutput> {
    return this.isPublic() ? fromPromise(this.publicBuildingRes.query(query)) : fromPromise(this.buildingRes.query(query));
  }

  private setBuildings(buildings: Building[]): void {
    this.setBuildingsMap(new Map<number, Building>(buildings.map(building => [building.id, building] as [number, Building])));
    this.setOptions(buildings.map(b => BuildingAdapter.getBuildingOption(b, this.config.getContentOption('showBuildingCode'))));
  }

  private setBuildingsMap(buildingsMap: Map<number, Building>): void {
    this.buildingsMapSource.next(buildingsMap);
  }

  private isPublic(): boolean {
    return (window.location.href.split('/').some(part => BuildingAdapter.publicPages.includes(part)));
  }
}
