import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class MomentNgbDateAdapter extends NgbDateAdapter<moment.Moment> {

  public fromModel(date: moment.Moment): NgbDateStruct {
    if (!date || !moment.isMoment(date)) {
      return null;
    }

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  public toModel(date: NgbDateStruct): moment.Moment {
    if (!date || !Number.isInteger(date.year) || !Number.isInteger(date.month) || !Number.isInteger(date.day)) {
      return null;
    }

    return moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD');
  }
}
