<div class="background">
  <div class="login-container">

    <img class="rkas-logo" src="/assets/gfx/logo_RKAS.svg" width="auto" height="auto" alt="RKAS logo"/>
    <img class="rkas-401" src="/assets/gfx/401.svg" width="auto" height="auto" alt="401"/>

    <h5 class="font-weight-light text-white">{{ content }}</h5>
    <a href="{{ loginUrl }}"class="btn btn-secondary">Tagasi</a>

  </div>
</div>

