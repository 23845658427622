import {Injectable} from '@angular/core';
import {IResourceMethodStrict, ResourceAction, ResourceParams, ResourceRequestMethod, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth.service';
import {AuthGuardedResource} from './auth-guarded.resource';

@Injectable({providedIn: 'root'})
@ResourceParams({withCredentials: true})
export class CacheResource extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/caching'));
    }

    @ResourceAction({
        path: '/clear',
        method: ResourceRequestMethod.Post
    })
    clear: IResourceMethodStrict<{}, {}, void, {}>;
}
