import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AppNotificationResource} from "@app/core/resource/app-notification.resource";

let nextId = 0;

@Component({
  selector: 'app-app-notification-modal',
  templateUrl: './app-notification-modal.component.html'
})
export class AppNotificationModalComponent implements OnInit, OnDestroy {
  private ngDestroy: Subject<void> = new Subject<void>();
  public showFormErrors = false;
  public progress = false;

  id = `app-admin-notification-${nextId++}`;

  form: FormGroup = new FormGroup({
    id: new FormControl(null, []),
    enabled: new FormControl(false, [Validators.required]),
    detail: new FormControl('', [Validators.required])
  });


  constructor(
    private appNotificationResource: AppNotificationResource,
    private toastr: ToastrService,
    public modalRef: BsModalRef
  ) {
    this.loadAppNotification();
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadAppNotification() {
    this.appNotificationResource.get().then((notification: any) => {
      this.form.patchValue({
        id: notification.id,
        enabled: notification.appNotificationEnable,
        detail: notification.appNotificationDetail,
      });
    }, _ => {
      this.form.patchValue({
        id: null,
        enabled: false,
        detail: '',
      });
    });
  }

  isInvalid(field: string) {
    const control = this.form.get(field);
    return control.invalid && (this.showFormErrors);
  }

  submit() {
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});

    if (this.form.valid) {
      this.progress = true;

      const input: any = this.prepareInputDtoForSubmit();

      this.appNotificationResource.post(input, {}, null).then(
        _ => {
          this.modalRef.hide();
          this.toastr.success('Teade on salvestatud!');
          this.loadAppNotification();
        },
        (error: any) => {
          this.toastr.error(JSON.stringify(error), 'Viga!');
        }
      ).finally(() => this.progress = false);
    }
  }

  delete() {
    this.appNotificationResource.delete({appNotificationId: this.form.get('id').value}).then(
      _ => {
        this.modalRef.hide();
        this.toastr.success('Teade on kustutatud!');
        this.loadAppNotification();
      },
      (error: any) => {
        this.toastr.error(JSON.stringify(error), 'Viga!');
      }
    ).finally(() => this.progress = false);
  }

  prepareInputDtoForSubmit(): any {
    const formModel = this.form.value;

    return {
      appNotificationEnable: formModel.enabled,
      appNotificationDetail: formModel.detail
    };
  }
}
