import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class PublicPermissionsService {

  private readonly publicPages = [
    'report-a-problem',
    'teata-probleemist',
    'lisa-pakkumine',
    'volitus-puudub',
    'aegunud'
  ];


  isPublicPage() {
    return window.location.href.split('/').some(part => this.publicPages.includes(part))
  }
}
