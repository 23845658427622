<nav>
    <ul>
        <li class="menu-link" routerLinkActive="active">
            <a routerLink="/orders">Töötaotlused</a>
            <a routerLink="/inspections" class="d-none"></a>
            <a routerLink="/messages-user" class="d-none"></a>
        </li>

        <li class="menu-link" routerLinkActive="active" *ngxPermissionsOnly="'appliance-rule.access'">
            <a routerLink="/appliance/schedule" class="d-none"></a>
            <a routerLink="/appliance/schedule/list">Hooldustööd</a>
            <a routerLink="/appliance/schedule/calendar" class="d-none"></a>
            <a routerLink="/appliance/plan" class="d-none"></a>
            <a routerLink="/appliance/orders" class="d-none"></a>
        </li>

        <li class="menu-link" routerLinkActive="active">
            <a routerLink="/acts/regular">Akteerimine</a>
            <a routerLink="/acts/quick" class="d-none"></a>
        </li>

        <li class="menu-link"
            routerLinkActive="active"
            *ngxPermissionsOnly="[
                'repair.access.governanceArea',
                'repair.access.company'
            ]">
            <a routerLink="/repairs">Remondikoond</a>
        </li>

        <ng-template [ngxPermissionsOnly]="contractPermissions">
            <li class="menu-link" routerLinkActive="active">
                <a routerLink="/contracts">Haldus</a>
                <a routerLink="/construction-contracts" class="d-none"></a>
                <a routerLink="/buildings" class="d-none"></a>
                <a routerLink="/reports" class="d-none"></a>
            </li>
        </ng-template>

        <ng-template [ngxPermissionsOnly]="'building-user.manage'" [ngxPermissionsExcept]="contractPermissions">
            <li class="menu-link" routerLinkActive="active">
                <a routerLink="/buildings">Haldus</a>
                <a routerLink="/contracts" class="d-none"></a>
                <a routerLink="/reports" class="d-none"></a>
                <a routerLink="/messages" class="d-none"></a>
            </li>
        </ng-template>

    </ul>
</nav>
