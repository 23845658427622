import {Injectable, OnDestroy} from '@angular/core';
import {HttpEvent} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, from, Observable, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {OrderCostOptionsDto} from '@app/core/resource-dto/order-cost-option';
import {PropertyRatesDto} from '@app/core/resource-dto/property-rates';
import {OrderEventDto} from '@app/core/resource-dto/order-event';
import {OrderCostsDto} from '@app/core/resource-dto/order-cost';
import {OrderFileDto} from '@app/core/resource-dto/order-file';
import {SharedDto} from '@app/core/resource-dto/shared-dto';
import {OrderDto, OrderSourceType} from '@app/core/resource-dto/order';
import {AuthService} from '@app/core/services/auth.service';
import {CommentType} from '@app/core/constants/comment.type';
import {OrderRes} from '@app/core/resource/order.resource';
import {InspectedServiceRes} from '@app/core/resource/inspected-service.resource';
import {OrderEventRes} from '@app/core/resource/order-event.resource';
import {OrderFileRes} from '@app/core/resource/order-file.resource';
import {NotificationRes} from '@app/core/resource/notification.resource';
import OrderViewerRequest = OrderDto.OrderViewerRequest;
import Cost = OrderCostsDto.Cost;
import {InspectionDto} from '@app/core/resource-dto/inspection';
import CancelledInspectionOrder = InspectionDto.CancelledInspectionOrder;

@Injectable({providedIn: 'root'})
export class ManageService implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    private readonly loadingOrderSource = new BehaviorSubject<boolean>(true);
    private readonly orderSource = new ReplaySubject<OrderDto.Order>(1);
    private readonly eventsSource = new ReplaySubject<OrderEventDto.Event[]>(1);
    private readonly filesSource = new ReplaySubject<OrderFileDto.File[]>(1);
    private readonly costsSource = new ReplaySubject<OrderCostsDto.CostsOutput>(1);
    private readonly propertyRatesSource = new ReplaySubject<PropertyRatesDto.QueryOutput>(1);
    private readonly costOptionsSource = new ReplaySubject<OrderCostOptionsDto.CostOptionsOutput>(1);
    private readonly assigneesSource = new ReplaySubject<OrderDto.AssigneesQueryOutput>(1);
    private readonly extensionRequestDatetimeSource = new ReplaySubject<string>(1);
    private readonly extensionRequestCommentSource = new ReplaySubject<string>(1);
    private readonly orderApprovedSource = new ReplaySubject<boolean>(1);
    private readonly stateChangeSubject = new Subject<void>();

    public readonly loadingOrder$ = this.loadingOrderSource.asObservable().pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly commentsUpdated$ = new Subject<any>();
    public readonly costsUpdated$ = new Subject<any>();
    public readonly order$ = this.orderSource.asObservable();
    public readonly events$ = this.eventsSource.asObservable();
    public readonly files$ = this.filesSource.asObservable();
    public readonly costs$ = this.costsSource.asObservable();
    public readonly propertyRates$ = this.propertyRatesSource.asObservable();
    public readonly costOptions$ = this.costOptionsSource.asObservable();
    public readonly assignees$ = this.assigneesSource.asObservable();
    public readonly extensionRequestDatetime$ = this.extensionRequestDatetimeSource.asObservable();
    public readonly extensionRequestComment$ = this.extensionRequestCommentSource.asObservable();
    public readonly isOrderApproved$ = this.orderApprovedSource.asObservable();
    public readonly stateChange$ = this.stateChangeSubject.asObservable();

    private contextType = 'ORDER';
    public order: OrderDto.Order;
    public orders: OrderDto.Order[] = [];

    public static transitionStrFromEvent(eventType: string): string {
        if (eventType.startsWith('OREV_')) {
            eventType = eventType.replace(/OREV_/g, '');
        }

        return eventType.toLowerCase().replace(new RegExp('_', 'g'), '-');
    }

    constructor(
        private orderRes: OrderRes,
        private inspectedServiceRes: InspectedServiceRes,
        private eventRes: OrderEventRes,
        private orderFileRes: OrderFileRes,
        private notificationRes: NotificationRes,
        private authService: AuthService,
        private router: Router
    ) {
        this.listenToOrder();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public setContextType(contextType: string): void {
        this.contextType = contextType;
    }

    public loadAll(orderId: number): void {
        this.loadOrder(orderId);
        this.loadAssignees(orderId);
        this.loadEvents(orderId);
        this.loadCosts(orderId);
        this.loadCostOptions(orderId);
        this.loadFiles(orderId);
    }

    public updateOrder(): Observable<void> {
        return new Observable<void>(subscriber => {
            const orderId = this.order?.id;
            if (!orderId) return subscriber.error('Order id missing!');

            const input = OrderDto.GetOrderInput.from(orderId, this.contextType === 'ACT' ? CommentType.ACT : CommentType.ORDER);
            this.orderRes.get(input)
                .then(result => {
                    this.setOrder(result);

                    return subscriber.next();
                })
                .catch(error => {
                    this.router.navigate(['/']);

                    return subscriber.error(error);
                });
        });
    }

    public loadOrder(orderId: number): void {
        this.loadingOrderSource.next(true);
        const input = OrderDto.GetOrderInput.from(orderId, this.contextType === 'ACT' ? CommentType.ACT : CommentType.ORDER);
        this.orderRes.get(input).then(
            res => {
                this.setOrder(res);
                this.loadingOrderSource.next(false);
            },
            _ => this.router.navigate(['/']));
    }

    public loadEvents(orderId: number): void {
        this.eventRes.query({orderId}).then(events => this.setEvents(events.events));
    }

    public loadCosts(orderId: number): void {
        this.orderRes.getCosts({orderId}).then(this.setCosts.bind(this));
    }

    public loadCostOptions(orderId: number): void {
        this.orderRes.getCostOptions({orderId}).then(this.setCostOptions.bind(this));
    }

    public loadFiles(orderId: number): void {
        this.orderRes.queryFiles({orderId}).then(files => this.setFiles(files.files));
    }

    public loadAssignees(orderId: number): void {
        this.orderRes.queryAssignees({orderId}).then(this.setAssignees.bind(this));
    }

    public setOrder(order: OrderDto.Order): void {
        this.orderSource.next(order);
    }

    public setOrderApproved(state: boolean): void {
        this.orderApprovedSource.next(state);
    }

    public setEvents(events: OrderEventDto.Event[]): void {
        this.eventsSource.next(events);

        const extensionEvents = events.filter(e => e.orderEventType === 'OREV_ASK_EXTENSION');

        if (!!extensionEvents?.length) {
            const latestEvent = extensionEvents.reduce((a, b) => {
                return new Date(a.eventTimestamp) > new Date(b.eventTimestamp) ? a : b;
            });

            this.extensionRequestDatetimeSource.next(latestEvent.eventTimestamp);
            this.extensionRequestCommentSource.next(latestEvent.comment);
        } else {
            this.extensionRequestDatetimeSource.next(null);
            this.extensionRequestCommentSource.next(null);
        }
    }

    public setFiles(files: OrderFileDto.File[]): void {
        this.filesSource.next(files);
    }

    public setCosts(costs: OrderCostsDto.CostsOutput): void {
        this.costsSource.next(costs);
    }

    public setPropertyRates(propertyRates: PropertyRatesDto.QueryOutput): void {
        this.propertyRatesSource.next(propertyRates);
    }

    public setCostOptions(costOptions: OrderCostOptionsDto.CostOptionsOutput): void {
        this.costOptionsSource.next(costOptions);
    }

    public setAssignees(assignees: OrderDto.AssigneesQueryOutput): void {
        this.assigneesSource.next(assignees);
    }

    public clearNotifications(): void {
        if (!this.order || !this.order.notifications) {
            return;
        }

        this.order.notifications.forEach((notification: OrderDto.Notification) => {
            this.notificationRes.delete({notificationId: notification.id});
        });
    }

    public createComment(orderId: number, text: string, userIds?: number[]): Promise<any> {
        const input: OrderDto.CommentInput = new OrderDto.CommentInput();
        input.orderId = this.order.id;
        input.text = text;
        input.commentType = this.contextType === 'ACT' ? 'ORCO_ACT' : 'ORCO_ORDER';
        if (userIds) {
            input.userIds = userIds;
        }

        return this.orderRes.saveComment(input, null, {orderId: input.orderId}).then(() => this.commentsUpdated$.next());
    }

    public getOrders(ids: number[]) {
        ids.forEach(id => {
            this.orderRes.get(null, null, {orderId: id}).then(order => {
                this.orders.push(order);
            });
        });
    }

    public getAllOrders() {
        return this.orders;
    }

    public triggerChange(): void {
        this.stateChangeSubject.next();
    }

    approveOrder() {
        const order: OrderDto.OrderInput = new OrderDto.OrderInput();

        order.requesterNotUser = this.order.requesterNotUser;
        order.requesterName = this.order.requesterName;
        order.requesterEmail = this.order.requesterEmail;
        order.requesterPhone = this.order.requesterPhone;
        order.requesterUser = this.order.requesterUser ?
            new SharedDto.IdInput(this.order.requesterUser.id) :
            new SharedDto.IdInput(null);
        order.requesterCompany = this.order.requesterCompany ?
            new SharedDto.IdInput(this.order.requesterCompany.id) :
            new SharedDto.IdInput(null);
        order.building = this.order.building ?
            new SharedDto.IdInput(this.order.building.id) :
            new SharedDto.IdInput(null);
        order.location = this.order.location;
        order.service = this.order.service ?
            new SharedDto.IdInput(this.order.service.id) :
            new SharedDto.IdInput(null);
        order.sla = this.order.sla ?
            new SharedDto.IdInput(this.order.sla.id) :
            new SharedDto.IdInput(null);
        order.inspection = this.order.inspection;
        order.description = this.order.description;
        order.isSpecificDueDate = this.order.isSpecificDueDate;
        order.dueDateTime = this.order.dueDateTime ? moment(this.order.dueDateTime, 'DD.MM.YYYY HH:mm').format() : null;
        order.deadlineHours = this.order.deadlineHours;
        order.satisfactionType = this.order.satisfactionType;
        order.satisfactionComment = this.order.satisfactionComment;
        order.propertyManagersOrder = this.order.isPropertyManagersOrder;
        order.specialWorkOfFloorsOrder = this.order.isSpecialWorkOfFloorsOrder;
        order.specialWorkOfWindowsOrder = this.order.isSpecialWorkOfWindowsOrder;
        order.quotationDueDateTime = this.order.quotationDueDateTime
            ? moment(this.order.quotationDueDateTime, 'DD.MM.YYYY HH:mm').format() : null;

        return this.orderRes.update(order, null, {orderId: this.order.id});
    }

    public saveInspectedService(input: OrderDto.OrderInput): Promise<any> {
        if (input.inspection && input.inspection.id) {
            return this.inspectedServiceRes.update(input, null, {inspectionId: input.inspection.id});
        } else {
            return this.saveOrder(input);
        }
    }

    public saveOrder(input: OrderDto.OrderInput): Promise<OrderDto.Order> {
        if (input.id) {
            return this.orderRes.update(input, null, {orderId: input.id});
        } else {
            return this.orderRes.save(input);
        }
    }

    public saveQuotationQueDate(orderId: number, input: OrderDto.OrderQuotationDueDateRequest): Promise<any> {
        return this.orderRes.updateQuotationDueDate(input, null, {orderId});
    }

    public acceptQuotation(orderId: number, input: OrderDto.OrderQuotationAcceptRequest): Promise<any> {
        return this.orderRes.acceptQuotation(input, null, {orderId});
    }

    public approveQuotation(orderId: number): Promise<any> {
        return this.orderRes.approveQuotation({orderId});
    }

    public additionalRequest(orderId: number, input: OrderDto.OrderQuotationOfferCommentRequest): Promise<any> {
        return this.orderRes.addOfferComment(input, null, {orderId});
    }

    public savePublicOrder(input: any): Observable<HttpEvent<any>> {
        return this.orderFileRes.saveOrder(input);
    }

    public saveOrderTenants(orderId: number, input: OrderDto.TenantsInput): Promise<OrderDto.OrderTenant[]> {
        return this.orderRes.saveTenants(input, null, {orderId});
    }

    public saveComment(input: OrderDto.CommentInput): Promise<OrderDto.Comment> {
        return this.orderRes.saveComment(input, null, {orderId: input.orderId});
    }

    public saveCost(input: OrderCostsDto.CostInput): Promise<OrderCostsDto.CostsOutput> {
        if (input.id) {
            return this.orderRes.updateCost(input, null, {orderId: this.order.id, costId: input.id});
        } else {
            return this.orderRes.saveCost(input, null, {orderId: this.order.id});
        }
    }

    public deleteCost(costId: number): Promise<void> {
        return this.orderRes.deleteCost({orderId: this.order.id, costId});
    }

    public saveCosts(input: OrderCostsDto.CostsInput): Promise<OrderCostsDto.CostsOutput> {
        return this.orderRes.updateCosts(input, null, {orderId: this.order.id});
    }

    public submitWorkflowAction(orderId: number, input: OrderDto.EventInput): Promise<OrderDto.Status> {
        return this.orderRes.updateEvent(input, null, {orderId});
    }

    public saveAssignee(orderId: number, input: OrderDto.AssigneeInput): Promise<OrderDto.Assignee> {
        return this.orderRes.updateAssignee(input, null, {orderId});
    }

    public canShowCosts(permissions, order: OrderDto.Order, events: OrderEventDto.Event[], costs: Cost[]): boolean {
        if (!permissions['order.cost.view']) return false;
        if (order.orderStatusType === 'ORST_ACCEPTED' && !!costs?.length) return true;
        if (order.orderStatusType === 'ORST_CLOSED' && events.every(e => e.orderEventType !== 'OREV_CANCEL')) return true;

        return ['ORST_REQUESTED', 'ORST_ACCEPTED', 'ORST_CANCELLED'].indexOf(order.orderStatusType) === -1;
    }

    public updateBuilding(orderId: number, input: OrderDto.OrderBuildingInput): Promise<OrderDto.Building> {
        return this.orderRes.updateBuilding(input, null, {orderId});
    }

    private listenToOrder(): void {
        this.order$.pipe(takeUntil(this.ngDestroy)).subscribe((order: OrderDto.Order) => {
            this.order = order;
        });
    }

    public createApplianceOrder(applianceId: number, description: string): Promise<OrderDto.Order> {
        const input = {
            requesterNotUser: false,
            requesterUser: {id: this.authService.getCurrentUser().id} as SharedDto.IdInput,
            requesterCompany: {id: this.authService.getCurrentUserCompany().companyId} as SharedDto.IdInput,
            service: {id: this.order.service.id} as SharedDto.IdInput,
            building: {id: this.order.building.id} as SharedDto.IdInput,
            isSpecificDueDate: false,
            orderSourceType: 'ORSO_WORK_ORDER',
            costOwnerType: 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS',
            applianceIds: [applianceId],
            preventiveMaintenanceOrderId: this.order.id,
            description
        } as OrderDto.OrderInput;

        return this.saveOrder(input);
    }

    public verifyWork(orderId: number, input: OrderDto.OrderVerifyWorkRequest): Promise<any> {
        return this.orderRes.verifyWork(input, null, {orderId});
    }

    public setOrderViewer(state: boolean): Observable<void> {
        const request = new OrderViewerRequest();
        request.viewing = !!state;

        return from(this.orderRes.setOrderViewer(request, null, {orderId: this.order.id}));
    }

    public duplicateOrder(description: string, specialWork: OrderDto.OrderInput, sourceType: string, inspectionId: number) {
        const input = {
            requesterNotUser: false,
            requesterUser: {id: this.authService.getCurrentUser().id} as SharedDto.IdInput,
            requesterCompany: {id: this.authService.getCurrentUserCompany().companyId} as SharedDto.IdInput,
            requesterEmail: this.authService.getCurrentUser()?.email,
            requesterPhone: this.authService.getCurrentUser()?.phone,
            building: {id: this.order.building.id} as SharedDto.IdInput,
            isSpecificDueDate: false,
            orderSourceType: sourceType,
            costOwnerType: 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS',
            description,
            duplicateOrderId: this.order.id,
            ...specialWork
        } as OrderDto.OrderInput;

        if (!input.specialWorkOfWarranty && !input.specialWorkOfRepair && !!this.order?.service?.id) {
            input.service = new SharedDto.IdInput();
            input.service.id = this.order?.service?.id;
        }

        if (!!this.order.appliances.length) {
            input.applianceIds = this.order.appliances.map(a => a.id);
        }

        if (!!inspectionId) {
            input.inspection = {id: inspectionId} as SharedDto.IdInput;
        }

        return this.saveOrder(input);
    }

    public saveFiles(orderId: number) {
        return this.files$.pipe().subscribe(files => {
            files.forEach(file =>
                this.orderRes.saveFile(file, null, {orderId})
            );
        });
    }

    public saveInspectionOrder(row: CancelledInspectionOrder) {
        const input = {
            requesterNotUser: false,
            requesterUser: {id: this.authService.getCurrentUser().id} as SharedDto.IdInput,
            requesterCompany: {id: this.authService.getCurrentUserCompany().companyId} as SharedDto.IdInput,
            requesterEmail: this.authService.getCurrentUser()?.email,
            requesterPhone: this.authService.getCurrentUser()?.phone,
            building: {id: row?.buildingId} as SharedDto.IdInput,
            location: row?.location,
            description: row?.description,
            deadlineHours: row?.deadlineHours,
            isSpecificDueDate: row?.isSpecificDueDate,
            orderSourceType: OrderSourceType.ORSO_WORK_ORDER,
            inChargeCompanyId: row?.inChargeCompanyId,
            inChargeUserId: row?.inChargeUserId,
            isPropertyManagersOrder: row?.isPropertyManagersOrder,
            specialWorkOfWindowsOrder: row?.isSpecialWorkOfWindowsOrder,
            specialWorkOfFloorsOrder: row?.isSpecialWorkOfFloorsOrder,
            specialWorkOfWarranty: row?.isSpecialWorkOfWarranty,
            specialWorkOfRepair: row?.isSpecialWorkOfRepair,
            specialWorkOfMaintenance: row?.isSpecialWorkOfMaintenance,
            costOwnerType: 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS',
        } as OrderDto.OrderInput;

        if (!!row.dueDateTime) {
            input.dueDateTime = row.dueDateTime.toString();
        }

        return this.saveOrder(input);
    }
}
