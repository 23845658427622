<div class="container-fluid">
    <form class="sign-box">
        <header class="sign-title">Rakenduse kasutamiseks peab teil olema eelnevalt registreeritud kasutaja</header>
        <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <button class="btn btn-default btn-primary" [routerLink]="['/register']">Taotluse Esitamine</button>
            </div>
            <div class="col-sm-3"></div>
        </div>
    </form>
</div>
