import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './auth.service';
import {UserNotificationPermissionsResource} from '@app/core/resource/user-notification-permissions.resource';
import {UserLoginDto} from '@app/core/resource-dto/user-login';
import User = UserLoginDto.User;
import {UserNotificationPermissionsDto} from '@app/core/resource-dto/user-notification-permissions';
import UserNotificationSettingsDto = UserNotificationPermissionsDto.UserNotificationSettingsDto;
import UserNotificationSettingsResponse = UserNotificationPermissionsDto.UserNotificationSettingsResponse;

@Injectable({providedIn: 'root'})
export class UserNotificationPermissionService implements OnDestroy {

    private readonly ngDestroy = new Subject<void>();
    private readonly userNotificationPermissionsSource = new ReplaySubject<UserNotificationSettingsDto>(1);
    public readonly userNotificationPermissions$ = this.userNotificationPermissionsSource.asObservable();
    private currentUser: User = null;

    constructor(
        private authService: AuthService,
        private resource: UserNotificationPermissionsResource,
        private toastr: ToastrService
    ) {
        this.listenCurrentUser();
    }

    private listenCurrentUser(): void {
        this.authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe((user: User) => {
            this.currentUser = user;
            this.get();
        });
    }

    public get(): void {
        this.resource.get({userId: this.currentUser.id}).then(result => {
            const next = result as UserNotificationSettingsDto;
            next.isSubstitute = this.currentUser?.id !== this.currentUser?.userCompany?.user?.id;

            this.userNotificationPermissionsSource.next(next);
        });
    }

    public put(response: UserNotificationSettingsResponse): void {
        const update = response.roles.map((role) => {
            return {
                roleId: role.id,
                permissionIds: role.permissions.filter(permission => permission.isActive).map(permission => permission.id)
            };
        });

        this.resource.put(update, null, {userId: this.currentUser.id}).then((_) => {
            this.toastr.success('E-posti seadistused muudetud');
            this.get();
        }).catch(_ => {
            this.toastr.error('E-posti seadistuste muutmine ebaõnnestunud!');
        });
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
