import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'user-not-found',
    templateUrl: './user-not-found.component.html'
})
export class UserNotFoundComponent implements OnInit {

    constructor(private _router: Router, private _authService: AuthService) {
    }

    ngOnInit() {
    }
}
