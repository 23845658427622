import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from "@app/config";
import {filter, takeUntil} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from "@angular/router";
import { Subject} from "rxjs";

@Component({
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnDestroy {

  public content = '';
  private ngDestroy: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.ngDestroy)
    ).subscribe(event => {
      let content = this.route.root.firstChild?.snapshot?.data['content'];
      if(content) this.content = content;
    });
  }

  public get loginUrl() {
    return this.configService.get('casLoginUrl');
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
