import {Component} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AsideService} from '@app/core/components/aside/aside.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-aside',
    templateUrl: './aside.component.html'
})
export class AsideComponent {

    private readonly openSubject = new BehaviorSubject<boolean>(false);
    public readonly content$ = this.asideService.content$;
    public readonly open$ = this.openSubject.asObservable();

    public constructor(private asideService: AsideService) {
        this.content$.subscribe({next: x => this.openSubject.next(!!x)});
    }

    public close(): void {
        this.openSubject.next(false);
    }
}
