<div class="row">
  <div class="col-12">
    <h5 class="mb-0">
      Kinnistute uuendamine
    </h5>
  </div>
</div>

<div class="work-details-full-width pb-3" [formGroup]="form">
  <div class="work-details-1-row">
    <div class="work-details-1-col mt-2">
      <label class="work-details-1-label">
        Tüüp
      </label>
      <div class="w-100" style="margin-right:96px;">
        <ng-select
          formControlName="syncType"
          class="custom w-100"
          [items]="syncTypes"
          bindLabel="text"
          bindValue="id"
          [clearable]="true"
          [searchable]="false"
          [class.is-invalid]="isInvalid('syncType')"
        ></ng-select>
        <small class="invalid-feedback"
               [class.d-block]="isInvalid('syncType') && form.get('syncType').errors?.required">
          {{validationMessages?.syncType?.required}}
        </small>
      </div>
    </div>
    <div class="work-details-1-col mt-2 align-items-center">
      <label class="work-details-1-label">
        Alguskuupäev
      </label>
      <div class="w-100">
        <input class="form-control"
               formControlName="updatedAfter"
               placeholder=""
               ngbDatepicker #dp="ngbDatepicker"
               outsideDays="hidden"
               [class.is-invalid]="isInvalid('updatedAfter')"
               [displayMonths]="2"
               (click)="dp.toggle()">
        <small class="invalid-feedback"
               [class.d-block]="isInvalid('updatedAfter') && form.get('updatedAfter').errors?.required">
          {{validationMessages?.updatedAfter?.required}}
        </small>
      </div>
      <button class="btn btn-primary ml-2" type="button"  (click)="submit()">
        Uuenda
      </button>
    </div>
  </div>
</div>
