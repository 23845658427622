<div class="modal-header">

    <h4 class="modal-title pull-left">Kasutusjuhendid</h4>

    <button type="button" class="close pull-right" (click)="close()" aria-label="Sulge">
        <span class="submenu-hover" aria-hidden="true"></span>
        <span class="icon icon_close" aria-hidden="true"></span>
    </button>

</div>

<div class="modal-body">

    <div class="work-details-full-width">

        <div class="work-details-1-row" *ngIf="!!instructions">

            <div class="work-details-1-col p-2" *ngFor="let instruction of instructions; index as i">

                <ng-container *ngxPermissionsOnly="['instructions.manage', content(instruction.type)?.permission]">

                    <label class="work-details-1-label">{{ content(instruction.type)?.label }}</label>

                    <div class="w-100">

                        <div class="file-info">

                            <button *ngIf="instruction.type !== InstructionTypes.RKAS_INSTRUCTIONS"
                                    class="btn btn-link file-name p-0"
                                    (click)="downloadFile(instruction?.file?.id)">
                                {{ instruction?.file?.name }}
                            </button>

                            <a class="btn btn-link file-name p-0"
                               href="{{instruction.fileLink}}"
                               target="_blank">
                                {{ instruction.fileLink }}
                            </a>

                        </div>

                    </div>

                </ng-container>

            </div>

        </div>

    </div>

</div>
