import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AsideService {

    private readonly contentSubject = new BehaviorSubject<TemplateRef<any> | undefined>(undefined);
    public readonly content$ = this.contentSubject.asObservable();

    public setContent(content: TemplateRef<any> | undefined): void {
        this.contentSubject.next(content);
    }
}
