import {Injectable} from '@angular/core';
import {IResourceMethod, IResourceMethodStrict, Resource, ResourceAction,
        ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import User = UserLoginDto.User;
import {UserLoginDto} from "@app/core/resource-dto/user-login";

@Injectable({providedIn: 'root'})
@ResourceParams({withCredentials: true})
export class UserLoginRes extends Resource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected config: ConfigService
  ) {
    super(requestHandler);

    this.$setUrl(config.getBackendUrl('/users/login'));
  }

  @ResourceAction({
    method: ResourceRequestMethod.Post
  })
  login: IResourceMethodStrict<{ companyId: number, userCompanyId: number }, {}, void, User>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/refresh',
    method: ResourceRequestMethod.Get
  })
  refresh: IResourceMethod<{}, {}>;
}
