import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-navbar-badge',
  templateUrl: './navbar-badge.component.html',
  styleUrls: ['./navbar-badge.component.scss']
})
export class NavbarBadgeComponent {

  @Input()
  public amount: number;

  @Input()
  public innerClass = 'badge-danger';
}
