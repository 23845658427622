import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {NavbarService} from '@app/core/components/navbar/navbar.service';
import {SiteStatsRes} from '@app/core/resource/site-stats.resource';

@Injectable({providedIn: 'root'})
export class NavbarBadgeService implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();

    public constructor(
        private navbarService: NavbarService,
        private siteStatsRes: SiteStatsRes
    ) {}

    public updateBadge(): void {
        this.siteStatsRes.query().then((res) => {
            this.navbarService.update(
                res?.act?.count,
                res?.inspection?.count,
                res?.inspection?.submittedOverdue,
                res?.messageUser?.count
            );
        });
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

}
