import {Subscription} from 'rxjs';

export class FileRef {

  id: number = null;
  fileId: number = null;
  size: number = null;
  mimeType: string = null;
  name: string = null;
  path: string = null;

  isLoaded = false;
  isUploaded = false;
  uploadProgress = 1;

  isImage = false;
  dataUrl: string = null;
  naturalWidth: number;
  naturalHeight: number;
  orientation: {
    transform: string;
    flipWh: boolean;
  };

  file: File = null;

  uploadSubscription: Subscription;

  orderFileType: string;

  constructor(_?: number) { }
}
