import {Component} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {SyncModalComponent} from '@app/core/components/admin/sync-modal/sync-modal.component';
import {
    AppNotificationModalComponent
} from '@app/core/components/admin/app-notification-modal/app-notification-modal.component';
import {ArchiveModalComponent} from '@app/core/components/admin/archive-modal/archive-modal.component';
import {NavbarBadgeService} from '@app/core/services/navbar-badge.service';
import {BadgeState} from '@app/core/components/navbar/navbar-badge/badge-state';
import {NavbarService} from '@app/core/components/navbar/navbar.service';
import {AddInstructionsComponent} from '@app/order/instructions/add-instructions-component/add-instructions.component';
import {
    ProjectManagerNotificationModalComponent
} from '@app/core/components/admin/project-manager-notification-modal/project-manager-notification-modal.component';

@Component({
    selector: 'app-navbar-menu',
    templateUrl: './navbar-menu.component.html',
    styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent {

    public badgeSource: Observable<BadgeState> = this.navbarService.badges$;

    constructor(
        private modalService: BsModalService,
        private navbarService: NavbarService,
        private navbarBadgeService: NavbarBadgeService
    ) {
        this.navbarBadgeService.updateBadge();
    }

    public openSyncModal(): void {
        this.modalService.show(SyncModalComponent);
    }

    public openNotificationModal(): void {
        this.modalService.show(AppNotificationModalComponent);
    }

    public openSendProjectManagerNotificationModal(): void {
        this.modalService.show(ProjectManagerNotificationModalComponent);
    }

    public openArchiveModal(): void {
        this.modalService.show(ArchiveModalComponent);
    }

    public openInstructionEditModal(): void {
        this.modalService.show(AddInstructionsComponent);
    }
}
