import {enableProdMode} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import localeEt from '@angular/common/locales/et';
import {defineLocale, etLocale} from 'ngx-bootstrap/chronos';
import * as moment from 'moment';
import {environment} from './environments/environment';
import {AppModule} from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// moment
moment.locale('et');

// ngx-bootstrap
defineLocale('et', etLocale);

registerLocaleData(localeEt, 'et');
