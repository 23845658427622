import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sync-modal',
  templateUrl: './sync-modal.component.html'
})
export class SyncModalComponent {

  constructor(public modalRef: BsModalRef) { }
}
