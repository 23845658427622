import {Injectable} from '@angular/core';
import {ManageService} from '@app/order/manage.service';
import {OrderRes} from '@app/core/resource/order.resource';
import {OrderDto} from '@app/core/resource-dto/order';
import {OrderFileDto} from '@app/core/resource-dto/order-file';

@Injectable()
export class FileService {

  protected order: OrderDto.Order;

  public constructor(
    protected manageService: ManageService,
    protected orderRes: OrderRes
  ) {
    this.listenOrder();
  }

  public saveFile(input: OrderFileDto.FileInput): Promise<OrderFileDto.File> {
    if (input.id) {
      return this.orderRes.updateFile(input, null, {orderId: this.order.id, orderFileId: input.id});
    } else {
      return this.orderRes.saveFile(input,  null, {orderId: this.order.id});
    }
  }

  public deleteFile(id: number): Promise<void> {
    return this.orderRes.deleteFile({orderId: this.order.id, orderFileId: id});
  }

  private listenOrder(): void {
    this.manageService.order$.subscribe((order: OrderDto.Order) => {
      this.order = order;
    });
  }
}
