import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import {PropertyregistrySyncResource} from '@app/core/resource/propertyregistry-sync.resource';

@Component({
    selector: 'app-property-sync',
    templateUrl: './property-sync.component.html'
})
export class PropertySyncComponent {

  public readonly validationMessages = {
    syncType: {
      required: 'Palun vali tüüp',
    },
    updatedAfter: {
      required: 'Palun sisesta alguskuupäev'
    }
  };
  public readonly syncTypes = [
    {
      id: 'appliance_maintenance_schedule',
      text: 'Appliance maintenance'
    }, {
      id: 'appliance',
      text: 'Appliance'
    }, {
      id: 'appliance_type',
      text: 'Appliance type'
    }, {
      id: 'building',
      text: 'Building'
    }, {
      id: 'equipment',
      text: 'Equipment'
    }, {
      id: 'floor',
      text: 'Floor'
    }, {
      id: 'pack',
      text: 'pack'
    }, {
      id: 'property',
      text: 'Property'
    }, {
      id: 'room',
      text: 'Room'
    }, {
      id: 'tariff',
      text: 'Tariff'
    }
  ];
  public readonly form: FormGroup = new FormGroup({
    syncType: new FormControl(null, [Validators.required]),
    updatedAfter: new FormControl(moment(), [Validators.required])
  });
  public showFormErrors = false;
  public progress = false;

  constructor(
    private propertyregistrySyncRes: PropertyregistrySyncResource,
    private toastr: ToastrService
  ) { }

  public isInvalid(field: string): boolean {
    const control = this.form.get(field);

    return control.invalid && (this.showFormErrors);
  }

  public submit(): void {
    this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});
    if (!this.form.valid) return;

    this.progress = true;

    const input: any = this.prepareInputDtoForSubmit();

    this.propertyregistrySyncRes.propertyregistrySync({}, input, null).then(
      _ => this.toastr.success('Kinnistute uuendamine on käivitatud!'),
      error =>  this.toastr.error(JSON.stringify(error), 'Viga!')
    ).finally(() => this.progress = false);
  }

  private prepareInputDtoForSubmit(): any {
    const formModel = this.form.value;
    const input: any = {};
    input.type = formModel.syncType;

    if (formModel.updatedAfter) {
      input.updatedAfter = moment(formModel.updatedAfter, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD[T]HH:mm:ss');
    }

    return input;
  }
}
