import {Component} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-detail-modal',
  templateUrl: './user-detail-modal.component.html'
})
export class UserDetailModalComponent {

  public title: string;
  public user: {
    name: string;
    phone: string;
    email: string;
    company?: string;
  };

  constructor(public modalRef: BsModalRef) { }
}
