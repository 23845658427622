import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {BuildingDto} from '../resource-dto/building';

@Injectable({providedIn: 'root'})
@ResourceParams({
  withCredentials: false
})
export class PublicBuildingRes extends Resource {

    constructor(
        protected resourceHandler: ResourceHandler,
        protected config: ConfigService
    ) {
        super(resourceHandler);

        this.$setUrl(config.getBackendUrl('/buildings'));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<BuildingDto.QueryInput, BuildingDto.QueryOutput>;
}
