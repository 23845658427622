import { Component } from '@angular/core';
import {CreateOfferService} from "@app/order/create-offer/create-offer.service";

@Component({
  selector: 'app-create-offer-actions',
  templateUrl: './create-offer-actions.component.html'
})
export class CreateOfferActionsComponent {

  constructor(public offerService: CreateOfferService) {}

  save() {
    this.offerService.save();
  }
}
