<div class="main-container">
  <div class="sticky" *ngIf="isUserLoggedIn">
    <app-header #header></app-header>
    <app-navbar [hidden]="!!(isMobileView$ | async)" #navbar></app-navbar>
  </div>

  <app-sidenav [hidden]="!!(isMobileView$ | async)"></app-sidenav>

  <router-outlet></router-outlet>
</div>

<app-aside></app-aside>
<app-footer [hidden]="!!(isMobileView$ | async)"></app-footer>
<ng-container *ngTemplateOutlet="(footerComponent$ | async)"></ng-container>
