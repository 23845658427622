<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">Saada kiri töövõtja PJ-le</h4>
  <button type="button" class="close pull-right" (click)="cancel()" aria-label="Sulge">
    <span class="submenu-hover"></span>
    <span class="icon icon_close"></span>
  </button>
</div>

<div class="modal-body">
  <div class="work-details-full-width">
    <div class="work-details-1-row">
      <div class="work-details-1-col">
        <label class="work-details-1-label">Teavitus</label>
        <div class="w-100 overflow-auto">
          <pre>
Hea (nimi)

Olete lepingutega seotud projektijuht ning vastutate lepingu korrektse täitmise eest lepingu perioodil.

Töötaotluste menetlemiseks ja teenuse akteerimiseks palume enne lepingu alguskuupäeva:
    lisada ja/või määrata Kasutajaregistris userregistry.rkas.ee oma meeskonnaliikmetele vastavad rollid:
        dispetšer
        töödejuht
        jne
    siduda Worksis lepingute juures rollid ja isikud:
        Lepingu kood 1,
        Lepingu kood 2
        ...

Juhendi Kasutajaregistri ja Worksi kasutamiseks leiad siit: link.
Tuletame meelde, et kui korrashoiuobjektil muutuvad lepingu täitmisega seotud isikud, siis tuleb sellest informeerida kinnisvarahaldurit 7 päeva jooksul ja teostada muudatused ka Kasutajaregistris ja Worksis.

Lugupidamisega
Riigi Kinnisvara AS
          </pre>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer footer-padding">
  <button (click)="cancel()" type="button" class="btn btn-outline-danger mr-4">Katkesta</button>
  <button (click)="submit()" type="button" class="btn btn-outline-primary">Saada</button>
</div>
