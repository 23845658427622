import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormService} from '@app/order/form/form.service';
import {TempFileService} from '@app/order/create/temp-file.service';
import {BuildingRes} from '@app/core/resource/building.resource';
import {FileService} from '@app/order/edit/file.service';
import {ManageService} from '@app/order/manage.service';
import {DatePipe} from '@angular/common';
import {CreateQuestService} from '@app/order/create-quest/create-quest.service';
import {NavbarService} from '@app/core/components/navbar/navbar.service';
import {CreateQuestActionsComponent} from '@app/order/create-quest/create-quest-actions.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './create-quest.component.html',
  styleUrls: ['./create-quest.component.scss'],
  providers: [
    CreateQuestService,
    FormService,
    TempFileService,
    BuildingRes,
    { provide: FileService, useExisting: TempFileService },
    ManageService,
    DatePipe
  ],
  entryComponents: [CreateQuestActionsComponent]
})
export class CreateQuestComponent implements OnInit, OnDestroy {
  private ngDestroy: Subject<void> = new Subject<void>();
  public orderForm: FormGroup;

  @ViewChild('navActions', { static: true })
  public navTempalte: TemplateRef<any>;

  constructor(
    public createService: CreateQuestService,
    private formService: FormService,
    private navbarService: NavbarService
  ) {
    this.orderForm = formService.orderForm;
    createService.isMinistryPage$.pipe(takeUntil(this.ngDestroy)).subscribe(isMinistryPage => {
      formService.changeToPublicForm(isMinistryPage);
    });

  }

  ngOnInit() {
    this.navbarService.setActionsTemplate(this.navTempalte);
  }

  dismissErrors() {
    this.createService.showFormErrors = false;
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.navbarService.setActionsTemplate(null);
  }
}
