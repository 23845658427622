<ul class="nav">
  <li class="nav-breadcrumb">
    <span class="nav-link-current mr-3">{{ 'Uus taotlus' | translate }}</span>
    <button class="btn btn-outline-dark btn-sm text-uppercase mr-3" [routerLink]="'/report-a-problem' + ((createService.isMinistryPage$ | async) ? '/ministry' : '')" [routerLinkActive]="'btn-dark'">eng</button>
    <button class="btn btn-outline-dark btn-sm text-uppercase" [routerLink]="'/teata-probleemist' + ((createService.isMinistryPage$ | async) ? '/ministeerium' : '')" [routerLinkActive]="'btn-dark'">est</button>

  </li>
  <li class="nav-item ml-auto" *ngIf="!createService.formSent">
    <button type="button" class="btn btn-primary" (click)="save()">{{ 'Esita taotlus' | translate }}</button>
  </li>
</ul>
