<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">Lõppenud lepinguga töötaotluste arhiveerimine</h4>
  <button type="button" class="close pull-right" (click)="hide()" aria-label="Close">
    <span class="submenu-hover"></span>
    <span class="icon icon_close"></span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="(archiving | async) === false; else archivingTemplate">
    <h5>Lepingu otsing</h5>
    <div class="form-row custom-form-row">
      <div class="col-12">
        <label>Vali leping</label>
        <ng-select class="custom archive-modal-select"
                   bindValue="id"
                   loadingText="&hellip;"
                   notFoundText="Vastet ei leitud&hellip;"
                   typeToSearchText="Otsi&hellip;"
                   appendTo="modal-container"
                   [searchFn]="searchFn"
                   [items]="contracts$ | async"
                   [loading]="loadingContracts"
                   [clearable]="true"
                   [searchable]="true"
                   (change)="selectContract($event)">
          <ng-template ng-label-tmp let-item="item">
            <div class="h-1em">
              {{item.number}}-{{item.name}}
            </div>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <div>{{item.number + '-' + item.name }}</div>
          </ng-template>

        </ng-select>
      </div>
    </div>
  </div>
  <ng-template #archivingTemplate>
    Arhiveeriti {{!!archivedAmount ? archivedAmount: '&hellip;'}} töötaotlust
  </ng-template>
</div>

<div class="modal-footer">
  <button *ngIf="(archiving | async) === false; else archivingButtonTemplate"
          class="btn btn-primary"
          type="button"
          [disabled]="!selection || loadingContracts"
          (click)="doArchive()">
    Arhiveeri
  </button>
  <ng-template #archivingButtonTemplate>
    <button class="btn btn-outline-primary"
            type="button"
            (click)="hide()">
      Sulge
    </button>
  </ng-template>
</div>
