import {ReplaySubject} from 'rxjs';

export class Breadcrumb {

  public readonly name$ = new ReplaySubject<string>();

  public constructor(name: string) {
    this.name$.next(name);
  }

  public update(name: string): void {
    this.name$.next(name);
  }
}
