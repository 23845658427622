<div class="work-details-full-width" [formGroup]="form">

    <div class="work-details-1-row" [formGroupName]="formName">

        <div class="work-details-1-col pt-2 pb-2 justify-content-between">

            <label class="work-details-1-label m-0">{{label}}</label>

            <div class="w-100">

                <div *ngIf="name" class="d-flex justify-content-between">
                    {{name}}

                    <button class="btn btn-outline-primary btn-sm d-block no-wrap mr-2 mb-2"
                            type="button"
                            (click)="updateInput.emit(type)">
                        <em class="fas fa-pencil-alt mr-1"></em>Muuda
                    </button>

                </div>

                <div *ngIf="isEdit || isNew">

                    <instruction-files-section *ngIf="type !== RKAS_INSTRUCTIONS"
                                               [instructionFileType]="type">
                    </instruction-files-section>

                    <input *ngIf="type === RKAS_INSTRUCTIONS"
                           class="form-control w-100"
                           [formControlName]="inputName"
                           placeholder="Sisesta link">

                </div>
            </div>

        </div>

    </div>

</div>
