import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NavbarService} from '../navbar.service';

@Component({
    selector: 'app-navbar-breadcrumbs',
    templateUrl: './navbar-breadcrumbs.component.html'
})
export class NavbarBreadcrumbsComponent {

    public readonly actionsComponent$ = this.navbarService.actionsTemplate$;
    public readonly breadcrumbs$ = this.navbarService.breadcrumbs$;
    public readonly queryParams$ = this.route.queryParams;

    public constructor(
        private navbarService: NavbarService,
        private route: ActivatedRoute
    ) {}
}
