export enum CommentType {
  ORDER = 'ORNO_COMMENT',
  ACT = 'ACNO_ORDER_COMMENT'
}

// tslint:disable-next-line:no-namespace
export namespace CommentType {
  export const from = (input: string): CommentType => {
    for (const value of Object.values(CommentType)) {
      if (value === input) return value;
    }

    return null;
  };
}
