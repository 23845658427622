<div class="row mt-4">
  <div class="col-12">
    <h5 class="mb-0">
      Kasutajate uuendamine
    </h5>
  </div>
</div>

<div class="work-details-full-width pb-3" [formGroup]="form">
  <div class="work-details-1-row">
    <div class="work-details-1-col mt-2">
      <label class="work-details-1-label">
        Alguskuupäev
      </label>
      <div class="w-100">
        <input formControlName="updatedAfter"
               class="form-control"
               placeholder=""
               ngbDatepicker #dp="ngbDatepicker"
               outsideDays="hidden"
               [displayMonths]="2"
               [class.is-invalid]="isInvalid('updatedAfter')"
               (click)="dp.toggle()">
        <small class="invalid-feedback"
               [class.d-block]="isInvalid('updatedAfter') && form.get('updatedAfter').errors?.required">
          {{validationMessages?.updatedAfter?.required}}
        </small>
      </div>
      <button class="btn btn-primary ml-2" type="button" (click)="submit()">
        Uuenda
      </button>
    </div>
  </div>
</div>
