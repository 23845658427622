import {PublicPermissionsService} from "@app/shared/services/public-permission.service";
import {AuthService} from "@app/core/services/auth.service";

export function appInitializerFactory(publicPermissionsService: PublicPermissionsService, authService: AuthService): () => Promise<any> {
    return (): Promise<any> => {
        if (publicPermissionsService.isPublicPage()) {
          return new Promise<any>(resolve => {resolve(true)});
        }

        return authService.initLogin();
    };
}
