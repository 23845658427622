import {Component, OnDestroy, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfigService} from '@app/config';
import {AuthService} from '@app/core/services/auth.service';
import {UserNotificationPermissionService} from '@app/core/services/user-notification-permission.service';
import {UserLoginDto} from '@app/core/resource-dto/user-login';
import {RepresentativeModalComponent} from '@app/core/components/representative-modal/representative-modal.component';
import {
  UserNotificationPermissionsModalComponent
} from '@app/core/components/user-notification-permissions-modal/user-notification-permissions-modal.component';
import {NavigationEnd, Router} from '@angular/router';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {ResizeService} from '@app/core/services/resize.service';
import {ActiveMenu} from '@app/core/constants/active-menu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [BsDropdownDirective]
})
export class HeaderComponent implements OnDestroy {

  @ViewChild('dropdown')
  public dropdown: BsDropdownDirective;

  private readonly ngDestroy = new Subject<void>();
  private readonly pushRightClass = 'sidenav-open';
  public readonly logoPath = this.config.get('logo');
  public readonly isMobileView$ = this.resizeService.isMobileView$;

  public currentUser: UserLoginDto.User = null;
  public currentUserCompany: UserLoginDto.UserCompany = null;
  public emailButtonEnabled = false;
  public showUserCompanies = false;
  public activeMenu?: ActiveMenu;
  public activeMenuEnum = ActiveMenu;

  constructor(
    private config: ConfigService,
    private authService: AuthService,
    private userNotificationPermissionService: UserNotificationPermissionService,
    private modalService: BsModalService,
    private router: Router,
    private resizeService: ResizeService
  ) {
    this.authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe(user => this.currentUser = user);
    this.authService.userCompany$.subscribe(_ => this.currentUserCompany = this.authService.getCurrentUserCompany());

    this.userNotificationPermissionService.userNotificationPermissions$.subscribe((settings) => {
      this.emailButtonEnabled = settings?.roles?.findIndex(value => value.permissions.length > 0) > -1;
    });

    router.events.pipe(takeUntil(this.ngDestroy)).subscribe((val) => {
      if (val instanceof NavigationEnd && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public toggleSidebar(): void {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  public isToggled(): boolean {
    const dom: Element = document.querySelector('body');

    return dom.classList.contains(this.pushRightClass);
  }

  public selectRole(): void {
    this.modalService.show(RepresentativeModalComponent);
  }

  public toggleUserCompanies() {
    return this.showUserCompanies = !this.showUserCompanies;
  }

  public configureEmailSettings(): void {
    this.modalService.show(UserNotificationPermissionsModalComponent);
  }

  public logout(): void {
    this.authService.redirectToCasLogout();
  }

  public selectUserCompany(userCompany: UserLoginDto.UserCompany): void {
    this.authService.doUserCompanyLogin(userCompany);
    this.dropdown.hide();
  }

  public toggleSubMenuItem(menu: ActiveMenu | undefined): void {
    this.activeMenu = menu !== this.activeMenu ? menu : undefined;
  }
}
