import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler, ResourceParams, ResourceRequestMethod,
} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {QuotationDto} from "@app/core/resource-dto/quotation";
import {HttpClient, HttpRequest} from "@angular/common/http";

@Injectable({providedIn: 'root'})
@ResourceParams({
  withCredentials: false
})
export class PublicQuotationRes extends Resource {

  constructor(
    protected requestHandler: ResourceHandler,
    private config: ConfigService,
    private httpClient: HttpClient
  ) {
    super(requestHandler);

    this.$setUrl(config.getBackendUrl('/quotation'));
  }

  @ResourceAction({
    path: '/public/{!token}',
    method: ResourceRequestMethod.Get,
  })
  query: IResourceMethod<QuotationDto.PublicQueryInput, QuotationDto.PublicQuotation>;

  saveQuotation(quotation: any) {
    return this.httpClient.request(new HttpRequest<FormData>(
      'POST',
      this.config.getBackendUrl('/quotation/public'),
      quotation,
      {withCredentials: true}
    ));
  }
}
