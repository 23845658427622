import {MetadataDto} from "@app/core/resource-dto/metadata";
import {InstructionTypes} from "@app/order/instructions/instruction-types";

export namespace InstructionDto {

  export class Instruction {
    id: number;
    file: MetadataDto.IdAndName;
    fileLink: string;
    type: InstructionTypes;
  }

  export class InstructionInput {
    file: MetadataDto.IdAndName;
    fileLink: string;
    type: InstructionTypes;
  }
}
