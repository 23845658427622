import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationDto} from '@app/core/resource-dto/notification';
import {NotificationRes} from '@app/core/resource/notification.resource';
import InputDto = NotificationDto.InputDto;

@Component({templateUrl: './project-manager-notification-modal.component.html'})
export class ProjectManagerNotificationModalComponent implements OnDestroy {

  private readonly ngDestroy = new Subject<void>();

  constructor(
    private notificationRes: NotificationRes,
    private toastr: ToastrService,
    public modalRef: BsModalRef
  ) {}

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public submit(): void {
    const dto = {notificationType: 'PROJECT_MANAGER_NOTIFICATION'} as InputDto;

    this.notificationRes.save(dto)
      .then(_ => this.modalRef.hide())
      .catch(e => this.toastr.error('Viga!', JSON.stringify(e)));
  }

  public cancel(): void {
    this.modalRef.hide();
  }
}
