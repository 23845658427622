import {Component} from '@angular/core';
import {FormBuildingSectionComponent} from '@app/order/form/building-section/building-section.component';
import {BuildingAdapter} from '@app/order/form/building-section/building-adapter';

@Component({
  selector: '[public-building-section]',
  templateUrl: './public-building-section.component.html',
  providers: [BuildingAdapter]
})
export class PublicBuildingSectionComponent extends FormBuildingSectionComponent {}
