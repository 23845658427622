import {ElementRef, ViewChild} from "@angular/core";
import {FormArray, FormGroup} from "@angular/forms";
import {FileRef} from "@app/order/form/files-section/file-ref.model";
import {jpegOrientation} from "@app/shared/jpeg-orientation";
import {ConfigService} from "@app/config";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {FileRes} from "@app/core/resource/file.resource";
import {ConfirmModalComponent} from "@app/core/components/confirm-modal/confirm-modal.component";

export abstract class FileSectionAbstract {

  @ViewChild('uploadInput')
  uploadInput: ElementRef;

  form: FormGroup;
  filesArray: FormArray;

  constructor(
    protected config: ConfigService,
    protected fileRes: FileRes,
    protected modalService: BsModalService,
    protected toastr: ToastrService,
  ) {}

  public getTranslatedText(key: string) {
    return key;
  }

  public onDragOver(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public onDragLeave(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public btnRemoveClicked(index: number) {
    this.modalService.show(ConfirmModalComponent,  {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-dialog modal-sm',
      initialState: {
        title: this.getTranslatedText('Kas oled kindel?'),
        message: this.getTranslatedText('Kas oled kindel, et soovid faili kustutada?'),
        description: null,
        callback: (result: boolean) => {
          if (!result) {
            return;
          }

          let fileControl: FormGroup = (this.filesArray.controls[index] as FormGroup);
          let fileRef: FileRef = fileControl.value.fileRef;
          if (fileRef.uploadSubscription) {
            fileRef.uploadSubscription.unsubscribe();
          }
          this.removeFileControl(fileControl);
        }
      }
    });
  }

  public btnViewClicked(index: number) {
    let fileControl: FormGroup = (this.filesArray.controls[index] as FormGroup);
    if (fileControl && fileControl.value) {
      let items: any[] = [];
      if (fileControl.value.fileRef.isImage) {
        items.push({
          type: 'image',
          src: fileControl.value.fileRef.dataUrl,
          transform: null,
          flipWh: fileControl.value.fileRef.orientation ? fileControl.value.fileRef.orientation.flipWh : null,
          opts: {
            image: {
              src: fileControl.value.fileRef.dataUrl
            },
            caption: fileControl.value.description ? fileControl.value.description : ''
          }
        });
      } else {
        items.push({
          type: 'inline',
          src: '<div class="gallery-item"><div class="gallery-doc">\n' +
            '    <div class="gallery-doc-in">\n' +
            '        <i class="font-icon font-icon-page"></i>\n' +
            '        <p>' + fileControl.value.fileRef.name + '</p>\n' +
            '    </div>\n' +
            '</div></div>',
          opts: {
            caption: fileControl.value.description
          }
        });
      }

      jQuery.fancybox.open(items, {
        loop : false,
        arrows: true,
        buttons: [
          "zoom",
          "fullScreen",
          "download",
          "close"
        ],
        smallBtn: false,
        toolbar: true,
        afterShow: (instance: any, slide: any) => {
          if (slide.$image) {
            let transform = slide.transform;
            if (transform && transform.length && slide.flipWh) {
              transform += ' scale('+(slide.width / slide.height)+')';
            }
            if (transform && transform.length) {
              slide.$image.css('transform', transform);
              slide.$image.css('object-fit', 'contain');
              if (slide.flipWh) {
                let temp = slide.width;
                slide.width = slide.height;
                slide.height = temp;
              }
            }
            instance.update();
          }
        }
      });
    }
  }

  public onDrop(event: any): void {
    event.stopPropagation();
    event.preventDefault();

    let length;
    if (event.dataTransfer.items) {
      length = event.dataTransfer.items.length;
    } else {
      length = event.dataTransfer.files.length;
    }

    for (let i = 0; i < length; i++) {
      let entry;
      if (event.dataTransfer.items) {
        if (event.dataTransfer.items[i].getAsFile()) {
          entry = event.dataTransfer.items[i].getAsFile();
        }
      } else {
        if (event.dataTransfer.files[i]) {
          entry = event.dataTransfer.files[i];
        }
      }

      this.processFileUpload(entry);
    }
  }

  public upload(_: any) {
    let inputEl = this.uploadInput.nativeElement;
    if (inputEl.files.length == 0) return;

    let files: FileList = inputEl.files;

    for (let i = 0; i < files.length; i++) {
      this.processFileUpload(files[i]);
    }
  }

  public download(fileRef: FileRef) {
    var a = document.createElement('a');
    a.setAttribute('download', fileRef.name);
    a.href = fileRef.dataUrl;
    a.target = '_blank';
    a.innerHTML = '';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  protected processFileUpload(entry: any) {
    let uploadFileRef: FileRef = new FileRef();

    uploadFileRef.isLoaded = false;
    uploadFileRef.file = entry;
    uploadFileRef.mimeType = entry.type;
    uploadFileRef.isImage = !!uploadFileRef.mimeType.match('image.*');

    if (uploadFileRef.isImage) {
      jpegOrientation(uploadFileRef.file, function (base64img, orientation) {
        uploadFileRef.dataUrl = base64img;
        uploadFileRef.orientation = orientation;
        uploadFileRef.isLoaded = true;

        let dummyImg = new Image();
        dummyImg.onload = () => {
          uploadFileRef.naturalWidth = dummyImg.naturalWidth;
          uploadFileRef.naturalHeight = dummyImg.naturalHeight;
        };
        dummyImg.src = base64img;
      });
    } else {
      uploadFileRef.dataUrl = URL.createObjectURL(new Blob([uploadFileRef.file], {
        type: "application/octet-stream"
      }));
      uploadFileRef.isLoaded = true;
    }

    this.addFileControl(uploadFileRef);
  }

  protected abstract removeFileControl(fileControl: FormGroup);
  protected abstract addFileControl(fileRef: FileRef)
}
