import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, startWith, take, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {OrderDto} from '@app/core/resource-dto/order';
import {OrderFileDto} from '@app/core/resource-dto/order-file';
import {ConfigService} from '@app/config/config.service';
import {jpegOrientation} from '@app/shared/jpeg-orientation';
import {ManageService} from '../manage.service';
import {FileRef} from './files-section/file-ref.model';
import {atLeastOne} from '@app/shared/validators/at-least-one.validator';
import {FileService} from '@app/order/edit/file.service';
import {FileRes} from '@app/core/resource/file.resource';
import {LoggerService} from '@app/core/services/logger.service';
import {FileDto} from '@app/core/resource-dto/file';

@Injectable()
export class FormService implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    private readonly invalidOrderShareMapSource = new ReplaySubject<Map<number, any>>(1);
    private readonly files$ = this.manageService.files$;

    public readonly invalidOrderShareMap$ = this.invalidOrderShareMapSource.asObservable().pipe(startWith(new Map()));
    public readonly order$ = this.manageService.order$;
    public readonly filesArray: FormArray = new FormArray([]);
    public readonly orderForm: FormGroup = new FormGroup({
        requesterNotUser: new FormControl(false, Validators.required),
        requesterName: new FormControl('', Validators.required),
        requesterEmail: new FormControl('', [Validators.required, Validators.email]),
        requesterPhone: new FormControl('', [Validators.required]),
        requesterUser: new FormControl(null, Validators.required),
        requesterCompany: new FormControl(null, Validators.required),
        building: new FormControl(null, Validators.required),
        location: new FormControl(''),
        service: new FormControl(null),
        sla: new FormControl(null),
        inspection: new FormControl(null),
        description: new FormControl('', [Validators.required]),
        isSpecificDueDate: new FormControl(),
        dueDateTime: new FormControl(),
        plannedStart: new FormControl(),
        deadlineHours: new FormControl(),
        toDo: new FormControl(false),
        isSpecialWorkOfFloorsOrder: new FormControl(false),
        isSpecialWorkOfWindowsOrder: new FormControl(false),
        isSpecialWorkOfRepair: new FormControl(false),
        isSpecialWorkOfWarranty: new FormControl(false),
        isSpecialWorkOfMaintenance: new FormControl(false),
        costOwnerType: new FormControl(null)
    });
    public readonly propertyRatesGroup: FormGroup;

    private invalidOrderShareMap: Map<number, any>;

    public order: OrderDto.Order = new OrderDto.Order();
    public files: OrderFileDto.File[] = [];

    constructor(
        private config: ConfigService,
        private manageService: ManageService,
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private fileRes: FileRes,
        private fileService: FileService,
        private logger: LoggerService
    ) {
        this.propertyRatesGroup = this.formBuilder.group({
            propertyRatesArray: this.formBuilder.array([])
        });

        this.listenToOrder();
        this.listenToFiles();
        this.listenFilesArray();
        this.listenToForm();
    }

    public get propertyRatesArray() {
        return this.propertyRatesGroup.get('propertyRatesArray') as FormArray;
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public listenFilesArray() {
        this.filesArray.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
            value.forEach((innerValue: any) => {
                const fileRef = innerValue.fileRef;
                if (!fileRef.isUploaded && !fileRef.uploadSubscription) {
                    fileRef.uploadSubscription = this.fileRes.upload(fileRef.file).subscribe(
                        (event) => {
                            if (event.type === HttpEventType.Response) {
                                if (event.status === 200) {
                                    fileRef.isUploaded = true;
                                    fileRef.uploadProgress = 1;

                                    const response: FileDto.File = event.body as FileDto.File;
                                    fileRef.fileId = response.id;
                                    fileRef.size = response.size;
                                    fileRef.mimeType = response.mimeType;
                                    fileRef.name = response.name;
                                    fileRef.path = response.path;

                                    const fileInput = new OrderFileDto.FileInput();
                                    fileInput.fileId = response.id;
                                    fileInput.description = '';
                                    fileInput.orderFileType = fileRef.orderFileType;

                                    this.fileService.saveFile(fileInput).then((result: OrderFileDto.File) => {
                                        fileRef.id = result.id;
                                        if (this.order && this.order.id) {
                                            fileRef.dataUrl =
                                                this.config.getBackendUrl('/orders/' + this.order.id + '/files/' + fileRef.id);
                                        }
                                    });
                                }
                            } else if (event.type === HttpEventType.UploadProgress) {
                                fileRef.uploadProgress = event.loaded / event.total;
                            }
                        },
                        (error) => {
                            this.logger.error('Error uploading file', error);
                        }
                    );
                }
            });
        });
    }

    public changeToPublicForm(ministryPage: boolean): void {
        const removed = ['requesterNotUser', 'requesterUser', 'requesterCompany'];
        removed.forEach((key) => this.orderForm.removeControl(key));

        if (ministryPage) {
            const cleared = ['requesterName', 'requesterEmail', 'requesterPhone'];
            cleared.forEach(field => this.orderForm.get(field).clearValidators());
            this.orderForm.clearValidators();
        } else {
            this.orderForm.get('requesterName').setValidators([Validators.required]);
            this.orderForm.get('requesterEmail').setValidators([Validators.email]);
            this.orderForm.get('requesterPhone').clearValidators();
            this.orderForm.setValidators(atLeastOne(Validators.required, ['requesterEmail', 'requesterPhone']));
        }
    }

    public resetPropertyRatesArray(): void {
        while (this.propertyRatesArray.length !== 0) {
            this.propertyRatesArray.removeAt(0);
        }
    }

    public resetFormValueOnCancel(): void {
        const formValue: any = this.prepareFormModel();
        this.orderForm.setValue(formValue, {emitEvent: false});
    }

    public emptyFileList(): void {
        while (this.filesArray.length !== 0) {
            this.filesArray.removeAt(0);
        }
    }

    public addFileControl(fileRef: FileRef, description?: string): FormGroup {
        const fileControl: FormGroup = new FormGroup({
            fileRef: new FormControl(fileRef, [Validators.required]),
            description: new FormControl(description)
        });

        this.filesArray.push(fileControl);

        return fileControl;
    }

    public addPropertyRateControl(input: any): void {
        this.propertyRatesArray.push(
            new FormGroup({
                checked: new FormControl(input.checked, [Validators.required]),
                costSourceType: new FormControl(input.costSourceType === 'BUDGET' ? 'BUDGET' : 'ACTUAL'),
                rateInContract: new FormControl(input.rateInContract, [Validators.required]),
                rateInOrder: new FormControl(input.rateInOrder, [Validators.required]),
                roundedRateInOrder: new FormControl(input.roundedRateInOrder, [Validators.required]),
            }));
    }

    public removeFileControl(fileControl: FormGroup): void {
        const index: number = this.filesArray.controls.indexOf(fileControl);

        if (index >= 0) {
            this.filesArray.removeAt(index);
        }
    }

    public updateInvalidOrderShareMap(value): void {
        this.invalidOrderShareMapSource.next(value);
        this.invalidOrderShareMap = value;
    }

    public removeInvalidOrderShare(index): void {
        if (this.invalidOrderShareMap && this.invalidOrderShareMap.get(index)) {
            this.invalidOrderShareMap.delete(index);
            this.invalidOrderShareMapSource.next(this.invalidOrderShareMap);
        }
    }

    public clearInvalidOrderRateMap(): void {
        if (this.invalidOrderShareMap) {
            this.invalidOrderShareMap.clear();
            this.invalidOrderShareMapSource.next(this.invalidOrderShareMap);
        }
    }

    public resetPublicFormValue() {
        this.orderForm.reset();
    }

    public resetFilesValue(): void {
        this.emptyFileList();
        this.order$.pipe(take(1)).subscribe((order: OrderDto.Order) => {
            this.files.forEach(file => {
                const fileRef: FileRef = new FileRef();
                fileRef.id = file.id;
                fileRef.orderFileType = file.orderFileType;
                fileRef.fileId = file.fileId;
                fileRef.size = file.size;
                fileRef.mimeType = file.mimeType;
                fileRef.name = file.name;
                fileRef.isUploaded = true;
                fileRef.isLoaded = false;
                fileRef.isImage = false;
                if (fileRef.mimeType.match('image.*')) {
                    fileRef.isImage = true;

                    this.http.get(this.config.getBackendUrl('/orders/' + order.id + '/files/' + file.id), {
                        responseType: 'blob',
                        withCredentials: true
                    }).subscribe((blob: File | Blob) => {
                        jpegOrientation(blob, (base64img, orientation) => {
                            fileRef.dataUrl = base64img;
                            fileRef.orientation = orientation;
                            fileRef.isLoaded = true;

                            const dummyImg = new Image();
                            dummyImg.onload = () => {
                                fileRef.naturalWidth = dummyImg.naturalWidth;
                                fileRef.naturalHeight = dummyImg.naturalHeight;
                            };

                            dummyImg.src = base64img;
                        });
                    });
                } else {
                    fileRef.isLoaded = true;
                    fileRef.dataUrl = this.config.getBackendUrl('/orders/' + order.id + '/files/' + file.id);
                }
                this.addFileControl(fileRef, file.description);
            });
        });
    }

    private resetFormValue(): void {
        this.orderForm.setValue(this.prepareFormModel());
    }

    private prepareFormModel(): any {
        const order: OrderDto.Order = this.order;

        const valueMap: any = {
            requesterNotUser: order.requesterNotUser,
            requesterName: order.requesterName ? order.requesterName : '',
            requesterEmail: order.requesterEmail ? order.requesterEmail : '',
            requesterPhone: order.requesterPhone ? order.requesterPhone : '',
            requesterUser: order.requesterUser && order.requesterUser.id ? {
                id: order.requesterUser.id,
                text: order.requesterUser.name
            } : null,
            requesterCompany: order.requesterCompany && order.requesterCompany.id ? {
                id: order.requesterCompany.id,
                text: order.requesterCompany.name
            } : null,
            building: order.building && order.building.id ? {
                id: order.building.id,
                text: order.building.address + ' - ' + order.building.name,
                users: order.building?.users,
                propertyCode: order.propertyCode,
                managerId: order.building.managerId,
                ownerSupervisorUsers: order.building?.ownerSupervisorUsers,
                projectManager: order.building?.projectManager
            } : null,
            location: order.location ? order.location : null,
            service: order.service && order.service.id ? {
                id: order.service.id,
                text: order.service.code + ' - ' + order.service.name,
                service: order.service
            } : null,
            sla: order.sla && order.sla.id ? order.sla.id : null,
            inspection: order.inspection && order.inspection.id ? order.inspection.id : null,
            description: order.description ? order.description : '',
            isSpecificDueDate: !!order.isSpecificDueDate,
            dueDateTime: order.dueDateTime ? moment(order.dueDateTime) : null,
            plannedStart: order.plannedStart ? moment(order.plannedStart) : null,
            deadlineHours: order.deadlineHours || 0,
            toDo: !!order.isPropertyManagersOrder,
            isSpecialWorkOfFloorsOrder: !!order.isSpecialWorkOfFloorsOrder,
            isSpecialWorkOfWindowsOrder: !!order.isSpecialWorkOfWindowsOrder,
            isSpecialWorkOfRepair: !!order.isSpecialWorkOfRepair,
            isSpecialWorkOfWarranty: !!order.isSpecialWorkOfWarranty,
            isSpecialWorkOfMaintenance: !!order.isSpecialWorkOfMaintenance,
            costOwnerType: order.costOwnerType || 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS'
        };

        if (this.orderForm.contains('typicalNonCompliance')) {
            valueMap.typicalNonCompliance = '';
        }

        return valueMap;
    }

    private listenToOrder(): void {
        this.order$.pipe(takeUntil(this.ngDestroy))
            .subscribe((orderResult: OrderDto.Order) => {
                    this.order = orderResult;
                    this.resetFormValue();
                }
            );
    }

    private listenToFiles(): void {
        this.files$.pipe(takeUntil(this.ngDestroy))
            .subscribe(files => {
                    this.files = files;
                    this.resetFilesValue();
                }
            );
    }

    private listenToForm(): void {
        this.orderForm.get('service').valueChanges
            .pipe(takeUntil(this.ngDestroy), distinctUntilChanged())
            .subscribe({next: _ => this.orderForm.patchValue({sla: null})});
    }
}
