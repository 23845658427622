import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ReplaySubject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({templateUrl: './confirm-modal.component.html'})
export class ConfirmModalComponent implements OnInit, OnDestroy {

  @Input()
  public title = 'Palun kinnita';

  @Input()
  public message = 'Oled kindel?';

  @Input()
  public description;

  @Input()
  public cancelLabel = 'Katkesta';

  @Input()
  public confirmLabel = 'Jah';

  @Input()
  public callback: (result: boolean, comment: string) => void;

  @Input()
  public comment;

  @Input()
  public errorMessage;

  @Output()
  public readonly closed = new EventEmitter<any>();

  private readonly commentSource = new ReplaySubject();

  @Output()
  public readonly comment$ = this.commentSource.asObservable();

  private readonly resultSource = new ReplaySubject<boolean>();

  @Output()
  public readonly result$ = this.resultSource.asObservable();

  public readonly form: FormGroup = new FormGroup({
    comment: new FormControl('', [Validators.required])
  });
  public loading = false;

  public constructor(private modalRef: BsModalRef) {}

  public ngOnInit() {
    if (this.comment !== true) {
      this.form.get('comment').setValue(this.comment);
    }
  }

  public ngOnDestroy() {
    if (this.closed.closed) return;

    this.closed.next();
    this.closed.complete();
  }

  public close() {
    this.resultSource.next(false);
    this.resultSource.complete();
    if (this.callback) {
      this.callback(false, '');
    }
    this.modalRef.hide();
  }

  public confirm() {
    this.loading = true;

    const value = this.form.getRawValue();
    if (this.comment) {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();

      if (!this.form.valid) return;


      this.commentSource.next(value.comment);
      this.commentSource.complete();
    }

    this.resultSource.next(true);
    this.resultSource.complete();
    if (this.callback) {
      this.callback(true, value ? value.comment : '');
    }
    this.modalRef.hide();
  }

  public hasErrors(field) {
    return field.invalid && (field.dirty || field.touched);
  }
}
