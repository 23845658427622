import {SharedDto} from './shared-dto';
import {OrderFileDto} from './order-file';
import {MetadataDto} from '@app/core/resource-dto/metadata';
import {ApplianceDto} from '@app/core/resource-dto/appliance';
import {GpsCoordinatesModel} from '@app/core/models/gps-coordinates.model';
import {LocationModel} from '@app/core/resource-dto/location.model';

export namespace OrderDto {

  import ApplianceResponse = ApplianceDto.ApplianceResponse;
  import IdInput = SharedDto.IdInput;
  import ApplianceInstructionFile = ApplianceDto.ApplianceInstructionFile;
  import IdCodeAndName = MetadataDto.IdCodeAndName;
  import StatsResponse = MetadataDto.StatsResponse;

  export class Building {
    id: number;
    address: string;
    code: string;
    name: string;
    managerId: number;
    managerName: string;
    managerPhone: string;
    managerEmail: string;
    managerManagerId: number;
    users: number[];
    ownerSupervisorUsers: number[];
    projectManager: number[];
    propertyLocation: LocationModel;
  }

  export class User {
    constructor(id?: number, name?: string) {
      this.id = id;
      this.name = name;
    }

    id: number;
    name: string;
    phone?: string;
    email?: string;
  }

  export class Company {
    constructor(id?: number, name?: string) {
      this.id = id;
      this.name = name;
    }

    id: number;
    name: string;
  }

  export class Service {
    id: number;
    code: string;
    name: string;
    startDate: string;
    endDate: string;
  }

  export class Sla {
    id: number;
    serviceName: string;
    deadlineHours: number;
    slaType: string;
  }

  export class Inspection {
    id: number;
    code: string;
  }

  export class Status {
    id: number;
    orderStatusType: string;
    actions: any;
  }

  export class Notification {
    id: number;
    orderNotificationType: string;
  }

  export class EventInput {
    orderEventType: string;
    comment: string;
    satisfactionType: string;
    extension: string;
    location: GpsCoordinatesModel;
  }

  export class Assignee {
    comment: string;
  }

  export class AssigneeInput {
    assigneeId: number;
    comment: string;
  }

  export class Satisfaction {
    satisfactionType: string;
    satisfactionComment: string;
  }

  export class SatisfactionInput {
    satisfactionType: string;
    satisfactionComment: string;
  }

  export class Comment {
    id: number;
    orderId: number;
    text: string;
    timestamp: string;
    user: User;
  }

  export class CommentInput {
    orderId: number;
    text: string;
    commentType: string;
    userIds?: number[];
  }

  export class OrderAct {
    id: number;
    actable: boolean;
    actStatusType: string;
  }

  export class QueryInput {
    offset?: number;
    limit?: number;
    sortFields?: string;
    code?: string;
    orderStatusTypes?: string[];
    regionTypes?: string[];
    serviceType?: string;
    serviceCode?: string;
    description?: string;
    address?: string;
    search?: string;
    dueDateTime?: string;
    doneTimestampFrom: string;
    doneTimestampTo: string;
    acceptedTimestampFrom: string;
    acceptedTimestampTo: string;
    dueDateTimeFrom: string;
    dueDateTimeTo: string;
    isOverdue?: boolean;
    inspectionId?: number;
    inChargeCompanyName: string;
    requesterCompanyName: string;
    managerName: string;
    serviceName: string;
    buildingIds: number[];
    buildingType: string;
    inChargeUserName: string;
    requesterUserName: string;
    isPropertyManagersOrder: boolean;
    isSpecialWorkOfFloorsOrder: boolean;
    isSpecialWorkOfWindowsOrder: boolean;
    isSpecialWorkOfRepair: boolean;
    isSpecialWorkOfWarranty: boolean;
    isSpecialWorkOfMaintenance: boolean;
    confirmedBy: string;
    requesterName: string;
    doneTimestamp: Date;
    isAccident: boolean;
    onlyPlannedStart: boolean;
    isPreventiveMaintenance: boolean;
    applianceServiceIds?: number[];
    applianceType: string;
  }

  export class QueryOutput {
    offset?: number;
    limit?: number;
    count?: number;
    orders: OrderList[];
  }

  export class OrderList {
    id: number = null;
    code = '';
    description = '';
    orderStatusType: string = null;
    building: Building;
    service: Service;
    deadlineHours = 0;
    commentCount: number;
    notifications: Notification[];
    requesterName = '';
    requesterCompany: Company;
    createdByCompanyName = '';
    acceptedBy: User;
    inChargeCompany: Company;
    dueDateTime: string = null;
    acceptedTimestamp: string = null;
    doneTimestamp: string = null;
    verifiedByClient: boolean;
    location: string;
    orderSourceType: string;
    appliances: OrderApplianceResponse[];
    applianceAmount: number;
  }

  export class Order {
    id: number = null;
    createdTimestamp: string = null;
    acceptedTimestamp: string = null;
    code = '';
    requesterName = '';
    requesterNotUser = false;
    requesterEmail = '';
    requesterPhone = '';
    building: Building;
    propertyCode: string;
    location: string;
    requesterUser: User;
    requesterCompany: Company;
    description = '';
    actions: any;
    permissions: any;
    orderStatusType: string = null;
    service: Service;
    sla: Sla;
    inspection: Inspection;
    doneTimestamp: string = null;
    isSpecificDueDate = false;
    dueDateTime: string = null;
    plannedStart: string = null;
    plannedStartTime: string = null;
    plannedEndTime: string = null;
    plannedTimeComment: string = null;

    repairExternalId?: number | null = null;
    repairDescription?: string | null = null;

    maxMaintenanceDate: Date = null;
    inChargeCompany: Company;
    inChargeUser: User;
    ownerSupervisorUsers: User[];
    contractorManager: User;
    customerSupport: User;
    deadlineHours = 0;
    satisfactionType: string = null;
    satisfactionComment: string = null;
    extension: string = null;
    comments: Comment[];
    files: OrderFileDto.File[] = [];
    notifications: Notification[];
    acts: OrderAct[];
    isApproved: boolean;
    isPropertyManagersOrder: boolean;
    isSpecialWorkOfFloorsOrder: boolean;
    isSpecialWorkOfWindowsOrder: boolean;
    isSpecialWorkOfRepair: boolean;
    isSpecialWorkOfWarranty: boolean;
    isSpecialWorkOfMaintenance: boolean;
    commentCount: number;
    maintenanceObligatoryDocument: boolean;
    maintenanceFrequencyPeriod: MaintenanceFrequencyPeriod;
    maintenanceFrequencyValue: number;
    costOwnerType: string;
    tenants: OrderTenant[];
    confirmedBy: User;
    createdByCompanyName: string;
    contractObjectId: number;
    orderSourceType: OrderSourceType;
    commentNotificationReceivers: CommentNotificationReceiver[];
    quotations: OrderQuotation[];
    quotationDueDateTime: Date;
    appliances: OrderApplianceResponse[] = [];
    acceptedOffer: OrderQuotationOffer;
    offerAcceptDateTime: Date;
    quotationApprovedAt: Date;
    offerApprovalNeeded: boolean;
    verifiedByClient: boolean;
    currentUserViewing: boolean;
    applianceInstructionFile: ApplianceInstructionFile[];
    initialDueDatetime: string;
  }

  export class GetOrderInput {
    orderId: number;
    commentType?: string;

    static from = (orderId: number, type?: string): GetOrderInput => {
      const that = new GetOrderInput();
      that.orderId = orderId;

      if (type) {
        that.commentType = type;
      }

      return that;
    }
  }

  export class OrderBuildingInput {
    buildingId: number;
  }

  export class OrderInput {
    id: number;
    isInOrder: boolean; // Kasutatakse olenevalt päringust
    requesterNotUser: boolean;
    requesterName: string;
    requesterEmail: string;
    requesterPhone: string;
    requesterUser: SharedDto.IdInput;
    requesterCompany: SharedDto.IdInput;
    assignee: SharedDto.IdInput;
    building: SharedDto.IdInput;
    location: string;
    service: SharedDto.IdInput;
    sla: SharedDto.IdInput;
    inspection: SharedDto.IdInput;
    description: string;
    isSpecificDueDate: boolean;
    dueDateTime: string;
    plannedStart: string;
    deadlineHours: number;
    satisfactionType: string;
    satisfactionComment: string;
    orderSourceType: OrderSourceType;
    propertyManagersOrder: boolean;
    specialWorkOfFloorsOrder: boolean;
    specialWorkOfWindowsOrder: boolean;
    specialWorkOfRepair: boolean;
    specialWorkOfWarranty: boolean;
    specialWorkOfMaintenance: boolean;
    costOwnerType: string;
    quotationDueDateTime: string;
    applianceIds: number[];
    preventiveMaintenanceOrderId: number;
    duplicateOrderId: number;
    inChargeCompanyId: number;
    inChargeUserId: number;
    isPropertyManagersOrder: boolean;

    public static fromOrder( order: Order): OrderInput {
      const input: OrderDto.OrderInput = new OrderDto.OrderInput();
      input.id = order.id;
      input.inspection = order.inspection ? new IdInput(order.inspection.id) : null;
      input.building = order.building ? new IdInput(order.building.id) : null;
      input.deadlineHours = order.deadlineHours;
      input.description = order.description;
      input.dueDateTime = order.dueDateTime;
      input.isSpecificDueDate = order.isSpecificDueDate;
      input.sla = order.sla ? new IdInput(order.sla.id) : null;
      input.location = order.location;
      input.orderSourceType = order.orderSourceType;
      input.requesterCompany = order.requesterCompany ? new IdInput(order.requesterCompany.id) : null;
      input.requesterEmail = order.requesterEmail;
      input.requesterName = order.requesterName;
      input.requesterNotUser = order.requesterNotUser;
      input.requesterPhone = order.requesterPhone;
      input.requesterUser = order.requesterUser ? new IdInput(order.requesterUser.id) : null;
      input.satisfactionComment = order.satisfactionComment;
      input.satisfactionType = order.satisfactionType;
      input.service = order.service ? new IdInput(order.service.id) : null;
      input.propertyManagersOrder = order.isPropertyManagersOrder;
      input.specialWorkOfFloorsOrder = order.isSpecialWorkOfFloorsOrder;
      input.specialWorkOfWindowsOrder = order.isSpecialWorkOfWindowsOrder;
      input.specialWorkOfWarranty = order.isSpecialWorkOfWarranty;
      input.specialWorkOfMaintenance = order.isSpecialWorkOfMaintenance;
      if (!!order.appliances?.length) {
        input.applianceIds = order.appliances.map(a => a.id);
      }
      return input;
    }
  }

  export class TenantsInput {
    tenants: Tenant[];
  }

  export class OrderTenant {
    id: number;
    kihlId: number;
    tenantName: string;
    rentalCode: string;
    contractNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    incomeSourceType: string;
    useActual: boolean;
    rateInContract: number;
    rateInOrder: number;
    allocationType: string;
  }

  export class Tenant {
    tenantName: string;
    rentalCode: string;
    contractNumber: string;
    kihlId: number;
    incomeSourceType: string;
    useActual: boolean;
    rateInContract: number;
    rateInOrder: number;
  }

  export class QueryStatsInput {}

  export class QueryStatsOutput {
    count: number;
    activeCount: number;
    overdueCount: number;
    accidentCount: number;
    plannedStartCount: number;
    preventiveMaintenanceCount: number;
    orderStatusTypes: OrderStats[];
    warrantyOrderCount: number;
    specialWorkOfRepairCount: number;
  }

  export class QueryWarrantyStatsOutput extends StatsResponse {
    activeCount: number;
    overdueCount: number;
    orderStatusTypes: OrderStats[];
  }

  export class OrderStats {
    orderStatusType: string;
    count: number;
  }

  export class AssigneesQueryOutput {
    dispatchers: ContractorPerson[];
    worksManagers: ContractorPerson[];
    specialists: ContractorPerson[];
  }

  export class ContractorPerson {
    id: number;
    name: string;
    email: string;
    phone: string;
    personalCode: string;
  }

  export class CommentNotificationReceiver {
    userId: number;
    userName: string;
    roles: CommentNotificationReceiverRole[];
  }

  export class CommentNotificationReceiverRole {
    code: string;
    name: string;
  }

  export class ArchivedOrderResponse {
    orderId: number;
    code: string;
  }

  export class OrderQuotation {
    id: number;
    accessToken: string;
    recipientEmail: string;
    emailText: string;
    companyName: string;
    offers: OrderQuotationOffer[];
    comments: OrderQuotationComment[];
    createdAt: string;
  }

  export class OrderQuotationOffer {
    id: number;
    companyName: string;
    recipientEmail: string;
    clientComment: string;
    managerComments: OfferManagerComment[];
    sum: number;
    hourlyRate: number;
    materialCost: number;
    file: OrderQuotationFile;
    createdAt: string;
  }

  export class OfferManagerComment {
    text: string;
    createdAt: string;
  }

  export class OrderQuotationFile {
    id: number;
    name: string;
    type: string;
    createdAt: string;
  }

  export class OrderQuotationComment {
    text: number;
    createdAt: string;
  }

  export class OrderQuotationOfferCommentRequest {
    offerId: number;
    email: string;
    text: number;
  }

  export class OrderApplianceMaintenancesRequest {
    data: OrderApplianceMaintenances[];
    duration?: number;
    location?: GpsCoordinatesModel;
  }

  export class OrderApplianceMaintenances {
    id: number;
    stateTypeAfterMaintenance: string;
    maintenanceStatusType: string;
    applianceChanged: boolean;
    newApplianceName: string;
    comment: string;
  }

  export class OrderQuotationDueDateRequest {
    quotationDueDateTime: string;
  }

  export class OrderQuotationPutRequest {
    recipients: OrderQuotationRecipient[];
    emailText: string;
  }

  export class OrderQuotationRecipient {
    email: string;
  }

  export class OrderQuotationAcceptRequest {
    offerId: number;
    approvalNeeded: boolean;
  }

  export class OrderQuotationAcceptResponse {
    offer: OrderQuotationOffer;
    offerAcceptDateTime: string;
    offerApprovalNeeded: boolean;
  }

  export class OrderVerifyWorkRequest {
    workIsVerified: boolean;
    comment: string;
  }

  export class OrderViewerRequest {
    viewing: boolean;
  }

  export class OrderApplianceResponse {

    public id: number;
    public code: string;
    public type: string;
    public identification: string;
    public statusType: string;
    public stateType: string;
    public groupType: string;
    public orderCount: number;
    public warrantyEndDate: Date;
    public manufacturer?: string;
    public description?: string;
    public information?: string;
    public modelNo?: string;
    public serialNum?: string;
    public maintenance: OrderApplianceMaintenanceData;
    public parentAppliance: ParentAppliance;
    public floor: IdCodeAndName;
    public room: IdCodeAndName;
    public orders: ApplianceOrder[];
    public amount?: number;
    public amountUnit?: string;

    public static fromApplianceModel(model: ApplianceResponse): OrderApplianceResponse {
      const response = new OrderApplianceResponse();
      response.id = model.id;
      response.code = model.code;
      response.type = model.type;
      response.statusType = model.statusType;
      response.stateType = model.stateType;
      response.manufacturer = model.manufacturer;
      response.information = model.information;
      response.modelNo = model.modelNo;
      response.serialNum = model.serialNum;
      response.room = model.room;
      response.floor = model.floor;
      response.parentAppliance = new ParentAppliance();
      response.parentAppliance.id = model.parent?.id || null;
      response.parentAppliance.code = model.parent?.code || null;
      response.identification = model.identification;
      response.description = model.description;
      response.groupType = model.groupType;
      response.amount = model.amount;
      response.amountUnit = model.amountUnit;

      return response;
    }
  }

  export class ParentAppliance {
    id: number;
    code: string;
    identification: string;
    groupType: string;
  }

  export class ApplianceOrder {
    id: number;
    code: string;
    preventiveMaintenanceOrderId: number;
  }

  export class OrderApplianceMaintenanceData {
    id: number;
    stateTypeAfterMaintenance: string;
    statusType: string;
    applianceChanged: boolean;
    newApplianceName: string;
    comment: string;
  }

  export class InspectionNoteInput {
    inspectionNote: string;
    inspectionNoteResponse: string;

    constructor(inspectionNote: string, inspectionNoteResponse: string) {
      this.inspectionNote = inspectionNote;
      this.inspectionNoteResponse = inspectionNoteResponse;
    }
  }
}

export enum OrderSourceType {
  ORSO_INSPECTION= 'ORSO_INSPECTION',
  ORSO_PREVENTIVE_MAINTENANCE = 'ORSO_PREVENTIVE_MAINTENANCE',
  ORSO_WORK_ORDER = 'ORSO_WORK_ORDER',
  ORSO_WORK_ORDER_PUBLIC_MINISTRY = 'ORSO_WORK_ORDER_PUBLIC_MINISTRY',
  ORSO_WORK_ORDER_PUBLIC = 'ORSO_WORK_ORDER_PUBLIC'
}

export enum MaintenanceFrequencyPeriod {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  HALF_YEAR = 'HALF_YEAR',
  YEAR = 'YEAR',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
  FOUR_YEARS = 'FOUR_YEARS',
  FIVE_YEARS = 'FIVE_YEARS',
  EIGHT_YEARS = 'EIGHT_YEARS',
  TEN_YEARS = 'TEN_YEARS',
  TWELVE_YEAR = 'TWELVE_YEAR',
  FIFTEEN_YEARS = 'FIFTEEN_YEARS'
}
