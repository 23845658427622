import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from "@app/config/config.service";
import {filter, takeUntil} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {

  private ngDestroy: Subject<void> = new Subject<void>();

  public name = this.config.get('name');
  public helpdesk = this.config.get('helpdesk');

  private hideFooter: boolean = false;

  constructor(
    private config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntil(this.ngDestroy)
    ).subscribe(event => {
      this.hideFooter = !!this.route.root.firstChild?.snapshot?.data['hideFooter'];
    });
  }

  public get hide() {
    return this.hideFooter
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
