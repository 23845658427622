import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {CreateOfferService} from "@app/order/create-offer/create-offer.service";
import {QuotationDto} from "@app/core/resource-dto/quotation";
import {
  FormQuotationFilesSectionComponent
} from "@app/order/form/files-quest-section/files-quotation-section.component";

@Component({
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.scss'],
  providers: [CreateOfferService, FormQuotationFilesSectionComponent]
})
export class CreateOfferComponent implements OnDestroy {

  private readonly ngDestroy: Subject<void> = new Subject<void>();
  public quotation: QuotationDto.PublicQuotation;
  public formGroup = this.offerService.quotationForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public offerService: CreateOfferService
  ) {
    offerService.quotationSource.pipe(takeUntil(this.ngDestroy)).subscribe(quotation => {
      this.quotation = quotation;
    })

    this.route.params.pipe(takeUntil(this.ngDestroy)).subscribe(data => {
      if(!data["token"]) offerService.accessDeniedRedirect();

      offerService.loadOffer(data["token"])
    });
  }

  public dismissErrors() {
    this.offerService.showFormErrors = false;
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
