import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  IResourceMethodStrict,
  ResourceAction,
  ResourceHandler,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from '@app/core/services/auth.service';
import {InspectionDto} from '@app/core/resource-dto/inspection';
import {InspectionSettingsDto} from '@app/core/resource-dto/inspection-settings';
import {InspectionProblemDto} from '@app/core/resource-dto/inspection-problem';
import InspectionProblemRequest = InspectionProblemDto.InspectionProblemRequest;
import ProblemResponse = InspectionProblemDto.ProblemResponse;

@Injectable({providedIn: 'root'})
export class InspectionRes extends AuthGuardedResource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);
    this.$setUrl(config.getBackendUrl('/inspections'));
  }

  @ResourceAction({expectJsonArray: false})
  query: IResourceMethod<InspectionDto.QueryInput, InspectionDto.QueryOutput>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/stats',
    method: ResourceRequestMethod.Get
  })
  queryStats: IResourceMethod<{}, InspectionDto.QueryStatsOutput>;

  @ResourceAction({path: '/create-options'})
  queryCreateOptions: IResourceMethodStrict<InspectionDto.CreateOptionInput, any, any, InspectionDto.CreateOptionOutput>;

  @ResourceAction({ path: '/{!inspectionId}'})
  get: IResourceMethodStrict<{inspectionId: any}, {}, any, InspectionDto.Inspection>;

  @ResourceAction({path: '/{!inspectionId}/problems'})
  getProblems: IResourceMethodStrict<{inspectionId: any}, any, InspectionProblemRequest, ProblemResponse>;

  @ResourceAction({path: '/{!inspectionId}/events'})
  getEvents: IResourceMethodStrict<{inspectionId: number}, any, any, InspectionDto.Events.QueryOutput>;

  @ResourceAction({method: ResourceRequestMethod.Post})
  save: IResourceMethodStrict<InspectionDto.InspectionInput, any, void, InspectionDto.Inspection>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!inspectionId}'
  })
  update: IResourceMethodStrict<InspectionDto.UpdateInput, any, {inspectionId: number}, InspectionDto.Inspection>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!inspectionId}/event'
  })
  updateEvent: IResourceMethodStrict<InspectionDto.EventInput, any, {inspectionId: number}, InspectionDto.Inspection>;

  @ResourceAction({path: '/settings/{!userId}'})
  getSettings: IResourceMethodStrict<{userId: number}, any, any, InspectionSettingsDto.QueryOutput>;

  @ResourceAction({
    path: '/settings/{!userId}',
    method: ResourceRequestMethod.Put
  })
  updateSettings: IResourceMethodStrict<InspectionSettingsDto.QueryInput, any, {userId: number}, InspectionSettingsDto.QueryOutput>;

  @ResourceAction({
    path: '/{!inspectionId}/problem-stats',
    method: ResourceRequestMethod.Get
  })
  getProblemStats: IResourceMethodStrict<{inspectionId: any}, {}, any, InspectionDto.ProblemStatsOutput>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}'
  })
  delete: IResourceMethod<{id: number}, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/{!inspectionId}/files'
  })
  addFile: IResourceMethodStrict<{fileId: number}, any, {inspectionId: number}, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!inspectionId}/files/{!fileId}'
  })
  removeFile: IResourceMethod<{inspectionId: number, fileId: number}, void>;

  @ResourceAction({
    path: '/{!inspectionId}/cancelled-orders',
    method: ResourceRequestMethod.Get,
    expectJsonArray: true
  })
  getCancelledOrders: IResourceMethodStrict<{inspectionId: any}, {}, any, InspectionDto.CancelledInspectionOrders>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!inspectionId}/service-inspection-report'
  })
  updateServiceInspectionReport: IResourceMethodStrict<InspectionDto.ServiceInspectionReport[], any, {inspectionId: number}, InspectionDto.Inspection>;
}
