export namespace SharedDto {

    export class IdInput {
        id: number;

        constructor(id?: number) {
            this.id = id;
        }
    }
}
