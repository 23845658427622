import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ContractSyncResource} from "@app/core/resource/contract-sync.resource";

@Component({
  selector: 'app-contract-sync',
  templateUrl: './contract-sync.component.html'
})
export class ContractSyncComponent {

  public progress = false;
  public readonly syncTypes = [
    {
      value: 'SERVICE_CONTRACT',
      label: 'Hoolduslepingud'
    }, {
      value: 'LEASE_AGREEMENT',
      label: 'Rendilepingud'
    },
    {
      value: 'CONSTRUCTION_CONTRACT',
      label: 'Ehituslepingud'
    }
  ];
  public readonly form: FormGroup = new FormGroup({
    syncType: new FormControl(null, [Validators.required]),
  });

  constructor(
    private contractSyncRes: ContractSyncResource,
    private toastr: ToastrService
  ) {}

  public submit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) return;
    this.progress = true;

    let value = this.form.getRawValue().syncType;
    this.contractSyncRes.contractSync({}, {type: value}, null).then(
      _ => this.toastr.success('Lepingute uuendamine on käivitatud!'),
      error => this.toastr.error(JSON.stringify(error), 'Viga!')
    ).finally(() => this.progress = false);
  }

  public isInvalid(field): boolean {
    return field.invalid && (field.dirty || field.touched);
  }
}
