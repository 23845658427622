<div class="background">
  <div class="login-container">
    <img class="login-logo" src="/assets/gfx/logo_RKAS.svg" width="auto" height="auto" alt="RKAS_logo">

    <div class="property-container-width">
      <div class="property-container">
        <h4 class="text-white text-center meter-title-responsive mt-3">Hinnapakkumuse esitamine tööle</h4>
        <h6 class="text-white text-center">Tähtaeg {{ quotation?.dueDateTime | date:'dd.MM.yyyy HH:mm'}}</h6>

        <div class="building-container p-0">
          <div class="block-tabs-wrapper">
            <div class="block-tabs remove-borders">
              <button>Töö detailid</button>
            </div>
          </div>

          <div class="block-content open">
            <div class="form-row p-3 p-md-3 p-lg-4 m-0">
              <div class="col-12">

                <div class="form-row custom-form-row mt-0">
                  <div class="col-12">
                    <label>Hoone</label>
                    <span class="font-weight-bold">{{ quotation?.buildingAddress }}</span>
                  </div>
                </div>

                <div class="form-row custom-form-row">
                  <div class="col-12">
                    <label>Asukoht</label>
                    <span class="font-weight-bold">{{ quotation?.location }}</span>
                  </div>
                </div>

                <div class="form-row custom-form-row">
                  <div class="col-12">
                    <label>Haldur</label>
                    <app-user-link title="Haldur" [user]="quotation?.manager"></app-user-link>
                  </div>
                </div>

                <div class="form-row custom-form-row">
                  <div class="col-12">
                    <label>Probleem</label>
                    <span class="font-weight-bold">{{ quotation?.description }}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="building-container p-0" [formGroup]="formGroup">
          <div class="block-tabs-wrapper">
            <div class="block-tabs remove-borders">
              <button>Hinnapakkumus</button>
            </div>
          </div>

          <div class="block-content">
            <div class="form-row p-3 p-md-3 p-lg-4 m-0">
              <div class="col-12">

                <div class="alert alert-danger" *ngIf="offerService.showFormErrors">
                  <button type="button" class="close" aria-label="Close" (click)="dismissErrors()">
                    <em class="icon icon_close"></em>
                  </button>
                  Vormil esineb vigu!
                </div>
                <div class="alert alert-success w-100" *ngIf="offerService.offerSent">
                  <h6>Hinnapakkumus edastatud.</h6>
                </div>

                <div class="form-row custom-form-row ml-0 mr-0">
                  <div class="col-12 pl-0 pr-0">
                    <label for="companyName">Ettevõtte nimi</label>
                    <input class="form-control mw-100" id="companyName" formControlName="companyName"
                           [class.is-invalid]="offerService.formErrors.companyName">
                  </div>
                  <small class="invalid-feedback d-block" *ngIf="offerService.formErrors.companyName?.required">
                    {{offerService.formErrors.companyName?.required}}
                  </small>
                </div>


                <div class="form-row custom-form-row ml-0 mr-0">
                  <div class="col-12 pl-0 pr-0">
                    <label for="companyEmail">Ettevõtte e-mail</label>
                    <input type="email" class="form-control" id="companyEmail" formControlName="companyEmail"
                           [class.is-invalid]="offerService.formErrors.companyEmail">
                  </div>
                  <small class="invalid-feedback d-block" *ngIf="offerService.formErrors.companyEmail?.required">
                    {{offerService.formErrors.companyEmail?.required}}
                  </small>
                  <small class="invalid-feedback d-block" *ngIf="offerService.formErrors.companyEmail?.email">
                    {{offerService.formErrors.companyEmail?.email}}
                  </small>
                </div>

                <hr>

                <div class="form-row custom-form-row ml-0 mr-0">
                  <form-files-quotation-section></form-files-quotation-section>
                </div>

                <div class="form-row custom-form-row ml-0 mr-0">
                  <div class="col-12 pl-0 pr-0">
                    <label for="comment">Kommentaar</label>
                    <textarea class="form-control" rows="3" id="comment" formControlName="clientComment"></textarea>
                  </div>
                  <small class="form-text text-muted">Vajadusel saate haldurile pakkumuse kohta täiendavaid kommentaare
                    jätta.</small>
                </div>

                <div class="form-row custom-form-row ml-0 mr-0">
                  <div class="col-12 pl-0 pr-0">
                    <div class="d-flex mr-3 align-items-center position-relative">
                      <label for="price">Maksumus</label>
                      <div class="d-flex align-items-center">
                        <input type="number" id="price" class="form-control w-100px" formControlName="sum"
                               [class.is-invalid]="offerService.formErrors.sum">
                        <span class="pl-1 text-sm"> € (km-ta)</span>
                      </div>
                    <span class="custom-required text-muted" *ngIf="!offerService.formErrors.sum">* Kohustuslik</span>
                    <span class="custom-invalid d-block" *ngIf="offerService.formErrors.sum?.required">
                      {{offerService.formErrors.sum?.required}}
                    </span>
                    <span class="custom-invalid" *ngIf="offerService.formErrors.sum?.min">
                      {{offerService.formErrors.sum?.min}}
                    </span>
                    </div>

                    <div class="d-flex mr-3 position-relative">
                      <div class="d-flex align-items-center">
                        <div class="mr-2">Tunnihind</div>
                        <input type="number"
                               id="hourlyRate"
                               class="form-control w-100px"
                               formControlName="hourlyRate"
                               [class.is-invalid]="offerService.formErrors.hourlyRate"
                        >
                        <span class="pl-1 text-sm"> € (km-ta)</span>
                      </div>
                      <span class="custom-required text-muted" *ngIf="!offerService.formErrors.hourlyRate">*
                        Kohustuslik</span>
                    <span class="custom-invalid" *ngIf="offerService.formErrors.hourlyRate?.required">
                      {{offerService.formErrors.hourlyRate?.required}}
                    </span>
                    </div>

                    <div class="d-flex position-relative">
                      <div class="d-flex align-items-center">
                        <div class="mr-2">Materjali hind</div>
                        <input type="number"
                               id="materialCost"
                               class="form-control w-100px"
                               formControlName="materialCost"
                               [class.is-invalid]="offerService.formErrors.materialCost"
                        >
                        <span class="pl-1 text-sm"> € (km-ta)</span>
                      </div>
                      <span class="custom-required text-muted" *ngIf="!offerService.formErrors.materialCost">*
                        Kohustuslik</span>
                    <span class="custom-invalid"
                           *ngIf="offerService.formErrors.materialCost?.required">
                      {{offerService.formErrors.materialCost?.required}}
                    </span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-create-offer-actions></app-create-offer-actions>
      </div>
    </div>
  </div>
</div>
