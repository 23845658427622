import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AuthService} from "@app/core/services/auth.service";
import {UserLoginDto} from "@app/core/resource-dto/user-login";

@Component({
  templateUrl: './representative-modal.component.html',
})
export class RepresentativeModalComponent implements OnDestroy {

  private readonly ngDestroy: Subject<void> = new Subject<void>();
  public currentUser: UserLoginDto.User = null;

  constructor(
      private authService: AuthService,
      public modalRef: BsModalRef
  ) {
    this.authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe(user => this.currentUser = user);
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public selectUserCompany(userCompany: UserLoginDto.UserCompany): void {
    this.authService.doUserCompanyLogin(userCompany);
    this.modalRef.hide();
  }
}
