import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, shareReplay, startWith} from 'rxjs/operators';
import {NgxPermissionsStore} from 'ngx-permissions';

@Injectable({providedIn: 'root'})
export class PermissionsService {

    private permissionsSource = this.permissionsStore.permissionsSource;
    public readonly permissions$ = this.permissionsSource.asObservable()
        .pipe(startWith(this.permissionsSource.value), shareReplay(1));

    public constructor(private permissionsStore: NgxPermissionsStore) {}

    public permissionObservable(...permissions: string[]): Observable<boolean> {
        return this.permissions$
            .pipe(map(current => permissions.reduce((acc, curr) => acc || !!current[curr], false)));
    }

    public hasPermission(permission: string | string[]): boolean {
        if (!permission || (Array.isArray(permission) && !permission.length)) return true;

        return this.hasArrayPermission(Array.isArray(permission) ? permission : [permission]);
    }

    public hasPermissionAsync(permission: string | string[]): Promise<boolean> {
        if (!permission || (Array.isArray(permission) && !permission.length)) return Promise.resolve(true);

        return this.hasArrayPermissionAsync(Array.isArray(permission) ? permission : [permission]);
    }

    public setPermissions(permissions: string[]): void {
        this.permissionsSource.next(permissions.reduce((acc, name) => ({...acc, [name]: {name}}), {}));
    }

    private hasArrayPermission(permissions: string[]): boolean {
        return permissions.reduce((acc, curr) => acc || !!this.permissionsSource.value[curr], false);
    }

    private hasArrayPermissionAsync(permissions: string[]): Promise<boolean> {
        return of(permissions.reduce((acc, curr) => acc || !!this.permissionsSource.value[curr], false)).toPromise();
    }
}
