<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">Esindusõiguse valimine</h4>
  <button type="button" class="close pull-right" (click)="modalRef.hide()" aria-label="Close">
    <span class="submenu-hover"></span>
    <span class="icon icon_close"></span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info alert-flex">
        <h6>Siin saad valida, mis ettevõtte nimel soovid siseneda. Seda valikut saad pärast sisse logimist ka muuta.</h6>
      </div>
    </div>
  </div>

  <div class="role row" *ngFor="let userCompany of currentUser?.companies; let i = index" [class.current]="userCompany?.id == currentUser?.userCompany?.id">
    <div class="col-12 d-flex align-items-center justify-content-start">
      <img alt="person" class="role-img" height="auto" src="/assets/gfx/icons/person-24px-white.svg" width="auto">
      <button (click)="selectUserCompany(userCompany)" class="btn btn-role-selection">
        {{ userCompany.name }}
      </button>
    </div>
  </div>

  <ng-container *ngFor="let substitution of currentUser.substitutions">
    <div class="role row" *ngFor="let userCompany of substitution.actingAsUser.companies; let i = index" [class.current]="userCompany?.id == currentUser?.userCompany?.id">
      <div class="col-12 d-flex align-items-center justify-content-start">
        <img alt="person" class="role-img" height="auto" src="/assets/gfx/icons/person-24px-white.svg" width="auto">
        <button (click)="selectUserCompany(userCompany)" class="btn btn-role-selection">{{ userCompany.name }}
          ({{ substitution.actingAsUser.surname }} {{ substitution.actingAsUser.forename }})
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer footer-padding">
  <button (click)="modalRef.hide()" type="button" class="btn btn-outline-primary">Katkesta</button>
</div>
