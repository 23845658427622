import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';
import IdInput = SharedDto.IdInput;
import {ToastrService} from 'ngx-toastr';
import {NavbarBadgeService} from '@app/core/services/navbar-badge.service';
import {HttpEventType} from '@angular/common/http';
import {FileRef} from '@app/order/form/files-section/file-ref.model';
import {ConfigService} from '@app/config';
import {InspectionRes} from '@app/core/resource/inspection.resource';
import {ServiceRes} from '@app/core/resource/service.resource';
import {InspectedServiceRes} from '@app/core/resource/inspected-service.resource';
import {FileRes} from '@app/core/resource/file.resource';
import {SharedDto} from '@app/core/resource-dto/shared-dto';
import {InspectionDto} from '@app/core/resource-dto/inspection';
import {OrderDto} from '@app/core/resource-dto/order';
import Service = InspectionDto.Service;
import {ServiceDto} from '@app/core/resource-dto/service';
import Inspection = InspectionDto.Inspection;
import {FileDto} from '@app/core/resource-dto/file';
import CancelledInspectionOrders = InspectionDto.CancelledInspectionOrders;
import {InspectionProblemDto} from '@app/core/resource-dto/inspection-problem';
import InspectionProblemRequest = InspectionProblemDto.InspectionProblemRequest;
import ProblemResponse = InspectionProblemDto.ProblemResponse;

@Injectable()
export class ManageService implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    private readonly inspectionSource = new BehaviorSubject<InspectionDto.Inspection | undefined>(undefined);
    private readonly inspectionProblemsSource = new ReplaySubject<ProblemResponse>(1);
    private readonly inspectionEventsSource = new ReplaySubject<InspectionDto.Events.Event[]>(1);
    private readonly backToInProgressCommentSource = new ReplaySubject<string | null>(1);
    private readonly contractSource = new ReplaySubject<any>(1);
    private readonly buildingSource = new ReplaySubject<InspectionDto.Building>(1);
    private readonly inspectionProblemStatsSource = new ReplaySubject<InspectionDto.ProblemStatsOutput>(1);
    private readonly cancelledOrderSource = new ReplaySubject<CancelledInspectionOrders>(1);
    public readonly problemStats$ = this.inspectionProblemStatsSource.asObservable();
    public readonly inspection$ = this.inspectionSource.asObservable().pipe(filter(x => x !== undefined));
    public readonly inspectionProblems$ = this.inspectionProblemsSource.asObservable();
    public readonly inspectionEvents$ = this.inspectionEventsSource.asObservable();
    public readonly backToInProgressComment$ = this.backToInProgressCommentSource.asObservable();
    public readonly contract$ = this.contractSource.asObservable();
    public readonly building$ = this.buildingSource.asObservable();
    public readonly cancelledOrders$ = this.cancelledOrderSource.asObservable();

    public constructor(
        private config: ConfigService,
        private toastr: ToastrService,
        private router: Router,
        private navbarBadgeService: NavbarBadgeService,
        private inspectionRes: InspectionRes,
        private serviceRes: ServiceRes,
        private inspectedServiceRes: InspectedServiceRes,
        private fileRes: FileRes
    ) {}

    public get inspection(): InspectionDto.Inspection | undefined {
        return this.inspectionSource.value;
    }

    private static prepareInputDtoForSubmit(service: Service): OrderDto.OrderInput {
        const input: OrderDto.OrderInput = new OrderDto.OrderInput();
        input.isInOrder = service.isInspected;
        input.service = new IdInput(service.id);

        return input;
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public refresh(): void {
        const currentId = this.inspection?.id;
        if (!currentId) return;

        this.loadInspection(currentId);
    }

    public update(inspectionId: number, input: InspectionDto.UpdateInput): Promise<InspectionDto.Inspection> {
        return this.inspectionRes.update(input, null, {inspectionId}).then(res => {
            this.navbarBadgeService.updateBadge();

            return res;
        });
    }

    public addFile(inspectionId: number, fileId: number): Promise<void> {
        return this.inspectionRes.addFile({fileId}, null, {inspectionId}).then(res => {
            return res;
        });
    }

    public removeFile(inspectionId: number, fileId: number): Promise<void> {
        return this.inspectionRes.removeFile({inspectionId, fileId}).then(res => {
            return res;
        });
    }

    public delete(inspectionId: number): Promise<void> {
        return this.inspectionRes.delete({id: inspectionId}).then(() => this.navbarBadgeService.updateBadge());
    }

    public loadInspection(inspectionId: number, loadEvents: boolean = true): void {
        this.inspectionRes.get({inspectionId}).then(
            inspection => {
                this.setInspection(inspection);
                this.setBuilding(inspection.building);
            },
            _ => this.router.navigate(['/'])
        );

        if (!loadEvents) return;

        this.loadInspectionEvents(inspectionId);
    }

    public loadInspectionEvents(inspectionId: number): void {
        this.inspectionRes.getEvents({inspectionId}, null).then(result => this.setInspectionEvents(result.events));
    }

    public loadServices(serviceId: number, query: string = null): Promise<ServiceDto.ServiceItem> {
        return this.serviceRes.get({serviceId}, query);
    }

    public loadInspectionProblems(request?: InspectionProblemRequest, inspectionId?: number): void {
        this.inspectionRes.getProblems({inspectionId}, request).then(this.setInspectionProblems.bind(this));
    }

    public loadProblemStats(inspectionId: number): void {
        this.inspectionRes.getProblemStats({inspectionId}, null).then(this.setProblemStats.bind(this));
    }

    public setContract(contract): void {
        this.contractSource.next(contract);
    }

    public saveInspection(input: InspectionDto.InspectionInput): Promise<InspectionDto.Inspection> {
        return this.inspectionRes.save(input).then(res => {
            this.navbarBadgeService.updateBadge();

            return res;
        });
    }

    public setInspection(inspection: InspectionDto.Inspection): void {
        this.inspectionSource.next(inspection);
    }

    public setBuilding(building: InspectionDto.Building): void {
        this.buildingSource.next(building);
    }

    public submitWorkflowAction(inspectionId: number, input: InspectionDto.EventInput): Promise<InspectionDto.Inspection> {
        return this.inspectionRes.updateEvent(input, null, {inspectionId});
    }

    public saveServiceInspectionReport(
        inspectionId: number,
        input: InspectionDto.ServiceInspectionReport[]
    ): Promise<InspectionDto.Inspection> {
        return this.inspectionRes.updateServiceInspectionReport(input, null, {inspectionId});
    }

    public saveInspectedService(inspection: Inspection, service: Service) {
        const dto = ManageService.prepareInputDtoForSubmit(service);
        this.inspectedServiceRes.update(dto, null, {inspectionId: inspection.id}).then(
            _ => {
                this.loadInspection(inspection.id);
                this.loadInspectionProblems(null, inspection.id);
                this.loadProblemStats(inspection.id);
            },
            err => {
                this.toastr.error(JSON.stringify(err));
                this.loadInspection(inspection.id);
                this.loadInspectionProblems(null, inspection.id);
                this.loadProblemStats(inspection.id);
            }
        );
    }

    private setInspectionProblems(inspectionProblems: ProblemResponse): void {
        this.inspectionProblemsSource.next(inspectionProblems);
    }

    private setInspectionEvents(inspectionEvents: InspectionDto.Events.Event[]): void {
        this.inspectionEventsSource.next(inspectionEvents);

        if (inspectionEvents.length > 0 && inspectionEvents[0].inspectionEventType === 'INEV_BACK_TO_IN_PROGRESS') {
            this.backToInProgressCommentSource.next(inspectionEvents[0].comment);
        } else {
            this.backToInProgressCommentSource.next(null);
        }
    }

    private setProblemStats(problemStats: InspectionDto.ProblemStatsOutput): void {
        this.inspectionProblemStatsSource.next(problemStats);
    }

    public saveFile(inspection: InspectionDto.Inspection, innerValue: { fileRef: FileRef }): Promise<any> {
        return new Promise((resolve, reject) => {
            const fileRef = innerValue.fileRef;
            fileRef.uploadProgress = 0;

            if (!fileRef.isUploaded && !fileRef.uploadSubscription) {
                fileRef.uploadSubscription = this.fileRes.upload(fileRef.file).subscribe(
                    (event) => {
                        if (event.type === HttpEventType.Response) {
                            if (event.status === 200) {
                                fileRef.isUploaded = true;
                                fileRef.uploadProgress = 1;

                                const response: FileDto.File = event.body as FileDto.File;
                                fileRef.fileId = response.id;
                                fileRef.size = response.size;
                                fileRef.mimeType = response.mimeType;
                                fileRef.name = response.name;
                                fileRef.path = response.path;

                                this.addFile(inspection.id, response.id).then(() => {
                                    innerValue.fileRef.dataUrl =
                                        this.config.getBackendUrl('/inspections/' + inspection.id + '/files/' + response.id);
                                    inspection.files.push({
                                        id: response.id,
                                        name: fileRef.name,
                                        mimeType: response.mimeType
                                    });
                                    resolve(innerValue);
                                }).catch(reject);
                            }
                        } else if (event.type === HttpEventType.UploadProgress) {
                            fileRef.uploadProgress = event.loaded / event.total;
                        }
                    },
                    (error) => {
                        console.error('Error uploading file', error);
                        reject(error);
                    }
                );
            } else {
                resolve(innerValue);
            }
        });
    }

    public getCancelledOrders(inspectionId: number) {
        this.inspectionRes.getCancelledOrders({inspectionId}).then(value => this.cancelledOrderSource.next(value));
    }
}
