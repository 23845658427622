import {Component} from '@angular/core';
import {NavbarService} from './navbar.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent {

    public readonly breadcrumbs$ = this.navbarService.breadcrumbs$;

    constructor(private navbarService: NavbarService) {}

    protected readonly length = length;
}
