import {Injectable} from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "@app/core/services/auth.service";

@Injectable()
export class GDPRGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (!this.authService.getCurrentUser() || this.authService.getCurrentUser().gdprAccepted) {
      return true;
    } else {
      window.location.assign(this.authService.getGdprUrl());
      return false;
    }
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    return this.canActivate(childRoute, state);
  }
}
