import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FileRef} from '@app/order/form/files-section/file-ref.model';
import {HttpEventType} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {InstructionResource} from '@app/core/resource/instruction.resource';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {InstructionDto} from '@app/core/resource-dto/instruction';
import {InstructionTypes} from '@app/order/instructions/instruction-types';
import {MetadataDto} from '@app/core/resource-dto/metadata';
import {FileDto} from '@app/core/resource-dto/file';
import {FileRes} from '@app/core/resource/file.resource';

@Component({templateUrl: './add-instructions.component.html'})
export class AddInstructionsComponent implements OnDestroy {

    private readonly ngDestroy = new Subject<void>();

    public clientFile: FileDto.File = null;
    public contractorFile: FileDto.File = null;
    public applianceFile: FileDto.File = null;
    public rkasFileLink: string = null;

    public type: string;

    public readonly instructionForm = new FormGroup({
        client: new FormGroup({
            file: new FormControl(FileRef),
            type: new FormControl(null)
        }),
        contractor: new FormGroup({
            file: new FormControl(FileRef),
            type: new FormControl(null)
        }),
        rkas: new FormGroup({
            fileLink: new FormControl(null),
            type: new FormControl(null)
        }),
        appliance: new FormGroup({
            file: new FormControl(FileRef),
            type: new FormControl(null)
        })
    });

    public readonly CLIENT_INSTRUCTIONS = InstructionTypes.CLIENT_INSTRUCTIONS;
    public readonly CONTRACTOR_INSTRUCTIONS = InstructionTypes.CONTRACTOR_INSTRUCTIONS;
    public readonly RKAS_INSTRUCTIONS = InstructionTypes.RKAS_INSTRUCTIONS;
    public readonly CONTRACTOR_APPLIANCE_INSTRUCTIONS = InstructionTypes.CONTRACTOR_APPLIANCE_INSTRUCTIONS;

    public constructor(
        private modalRef: BsModalRef,
        private toastr: ToastrService,
        private instructionRes: InstructionResource,
        private fileRes: FileRes,
    ) {
        this.listenToClient();
        this.listenToContractor();
        this.listenToAppliance();
        this.loadInstructions();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public submit(): void {
        this.instructionForm.updateValueAndValidity();
        this.instructionForm.markAllAsTouched();

        const value = this.instructionForm.getRawValue();
        const observables: Promise<any>[] = [];

        const clientInput: InstructionDto.InstructionInput = new InstructionDto.InstructionInput();
        const clientFileInput: MetadataDto.IdAndName = new MetadataDto.IdAndName();

        const contractorInput: InstructionDto.InstructionInput = new InstructionDto.InstructionInput();
        const contractorFileInput: MetadataDto.IdAndName = new MetadataDto.IdAndName();

        const applianceInput: InstructionDto.InstructionInput = new InstructionDto.InstructionInput();
        const applianceFileInput: MetadataDto.IdAndName = new MetadataDto.IdAndName();

        const rkasInput: InstructionDto.InstructionInput = new InstructionDto.InstructionInput();

        if (value.client.file.isUploaded === true) {
            clientFileInput.id = value.client.file.fileId;
            clientFileInput.name = value.client.file.name;
            clientInput.file = clientFileInput;
            clientInput.type = value.client.type !== null ? value.client.type : InstructionTypes.CLIENT_INSTRUCTIONS;

            observables.push(this.instructionRes.update(clientInput));
        }

        if (value.contractor.file.isUploaded === true) {
            contractorFileInput.id = value.contractor.file.fileId;
            contractorFileInput.name = value.contractor.file.name;
            contractorInput.file = contractorFileInput;
            contractorInput.type = value.contractor.type !== null ? value.contractor.type : InstructionTypes.CONTRACTOR_INSTRUCTIONS;

            observables.push(this.instructionRes.update(contractorInput));
        }

        if (value.rkas.fileLink !== null) {
            rkasInput.type = value.rkas.type !== null ? value.rkas.type : InstructionTypes.RKAS_INSTRUCTIONS;
            rkasInput.fileLink = value.rkas.fileLink;

            observables.push(this.instructionRes.update(rkasInput));
        }

        if (value.appliance.file.isUploaded === true) {
            applianceFileInput.id = value.appliance.file.fileId;
            applianceFileInput.name = value.appliance.file.name;
            applianceInput.file = applianceFileInput;
            applianceInput.type = value.appliance.type !== null ? value.appliance.type : InstructionTypes.CONTRACTOR_APPLIANCE_INSTRUCTIONS;

            observables.push(this.instructionRes.update(applianceInput));
        }

        if (observables.length !== 0) {
            Promise.all(observables).then((_) => {
                this.modalRef.hide();
                this.toastr.success('Juhendi salvestamine õnnestus!');
            }).catch(e => {
                this.toastr.error('Juhendi salvestamine ebaõnnestus!');
                console.error('Juhendi salvestamine ebaõnnestus!' + (e.message ? ': ' + e.message : ''));
            });
        }
    }

    public uploadFile(fileRef: FileRef): void {
        if (!(!fileRef.isUploaded && !fileRef.uploadSubscription)) return;

        fileRef.uploadSubscription = this.fileRes.upload(fileRef.file)
            .pipe(takeUntil(this.ngDestroy))
            .subscribe({
                next: event => {
                    if (event.type === HttpEventType.Response) {
                        if (event.status === 200) {
                            fileRef.isUploaded = true;
                            fileRef.uploadProgress = 1;

                            const response: FileDto.File = event.body as FileDto.File;
                            fileRef.fileId = response.id;
                            fileRef.size = response.size;
                            fileRef.mimeType = response.mimeType;
                            fileRef.name = response.name;
                            fileRef.path = response.path;
                        }
                    } else if (event.type === HttpEventType.UploadProgress) {
                        fileRef.uploadProgress = event.loaded / event.total;
                    }
                },
                error: error => {
                    this.toastr.error('Faili salvestamine ebaõnnestus!');
                    console.error(error);
                }
            });
    }

    public updateInput(type: string): void {
        switch (type) {
            case InstructionTypes.CLIENT_INSTRUCTIONS:
                this.instructionForm.get('client').get('type').setValue(InstructionTypes.CLIENT_INSTRUCTIONS);
                break;
            case InstructionTypes.CONTRACTOR_INSTRUCTIONS:
                this.instructionForm.get('contractor').get('type').setValue(InstructionTypes.CONTRACTOR_INSTRUCTIONS);
                break;
            case InstructionTypes.RKAS_INSTRUCTIONS:
                this.instructionForm.get('rkas').get('type').setValue(InstructionTypes.RKAS_INSTRUCTIONS);
                break;
            case InstructionTypes.CONTRACTOR_APPLIANCE_INSTRUCTIONS:
                this.instructionForm.get('appliance').get('type').setValue(InstructionTypes.CONTRACTOR_APPLIANCE_INSTRUCTIONS);
                break;
        }
    }

    public close(): void {
        this.modalRef.hide();
    }

    private listenToClient(): void {
        this.instructionForm.get('client').get('file').valueChanges
            .pipe(takeUntil(this.ngDestroy), distinctUntilChanged())
            .subscribe((value: any) => this.uploadFile(value));
    }

    private listenToContractor(): void {
        this.instructionForm.get('contractor').get('file').valueChanges
            .pipe(takeUntil(this.ngDestroy), distinctUntilChanged())
            .subscribe((value: any) => this.uploadFile(value));
    }

    private listenToAppliance(): void {
        this.instructionForm.get('appliance').get('file').valueChanges
            .pipe(takeUntil(this.ngDestroy), distinctUntilChanged())
            .subscribe((value: any) => this.uploadFile(value));
    }

    private loadInstructions(): void {
        this.instructionRes.getAll().then(list => list.forEach(x => this.loadInstruction(x)));
    }

    private loadInstruction(instruction: InstructionDto.Instruction): void {
        switch (instruction?.type) {
            case InstructionTypes.CLIENT_INSTRUCTIONS:
                this.fileRes.getOne({id: instruction.file.id}).then(data => {
                    this.clientFile = data;
                });
                break;
            case InstructionTypes.CONTRACTOR_INSTRUCTIONS:
                this.fileRes.getOne({id: instruction.file.id}).then(data => {
                    this.contractorFile = data;
                });
                break;
            case InstructionTypes.CONTRACTOR_APPLIANCE_INSTRUCTIONS:
                this.fileRes.getOne({id: instruction.file.id}).then(data => {
                    this.applianceFile = data;
                });
                break;
            case InstructionTypes.RKAS_INSTRUCTIONS:
                this.rkasFileLink = instruction.fileLink;
                this.instructionForm.get('rkas').get('fileLink').setValue(instruction.fileLink);
                break;
        }
    }
}
