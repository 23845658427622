import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '@app/core/services/auth.service';
import {SessionTimeoutModalComponent} from '@app/core/components/session-timeout-modal/session-timeout-modal.component';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class SessionTimeoutService {

  private readonly showTimeoutModalDiff = 180000;
  private sessionExpireTime: Date;
  private sessionTimeoutModalRef: BsModalRef;
  private showTimeoutModalTimeout: number;

  public constructor(
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  public init(): void {
    this.authService.sessionExpireTime$.subscribe(date => {
      if (this.showTimeoutModalTimeout) {
        clearTimeout(this.showTimeoutModalTimeout);
      }
      this.sessionExpireTime = date;
      const timeoutMs = moment(date).diff(new Date(), 'ms');
      const showTimeoutMs: number = timeoutMs < this.showTimeoutModalDiff ? 1 : timeoutMs - this.showTimeoutModalDiff;

      this.showTimeoutModalTimeout = setTimeout(() => this.showSessionTimeoutModal(), showTimeoutMs);
    });
  }

  private showSessionTimeoutModal() {
    if (!!this.sessionTimeoutModalRef) return;

    this.sessionTimeoutModalRef = this.modalService.show(
      SessionTimeoutModalComponent,
      {
        backdrop : 'static',
        keyboard : false,
        initialState: {
          sessionExpireTime: this.sessionExpireTime
        }
      }
    );
    this.sessionTimeoutModalRef.content.close.subscribe(_ => this.sessionTimeoutModalRef = null);
  }
}
