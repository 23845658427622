export namespace OrderFileDto {

    export class File {
        id: number;
        fileId: number;
        size: number;
        mimeType: string;
        name: string;
        path: string;
        description: string;
        orderFileType: string;
    }

    export class FileInput {
        id: number;
        fileId: number;
        description: string;
        orderFileType: string;
    }

    export class QueryInput {
    }

    export class QueryOutput {
        files: File[];
    }
}
