import {Component} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import ContractLite = ContractDto.ContractLite;
import ExpiredContractModel = ContractDto.ExpiredContractModel;
import {ContractRes} from "@app/core/resource/contract.resource";
import {OrderRes} from "@app/core/resource/order.resource";
import {ContractDto} from "@app/core/resource-dto/contract";

@Component({templateUrl: './archive-modal.component.html'})
export class ArchiveModalComponent {

  private readonly contractsSource: Subject<ExpiredContractModel[]> = new Subject<ExpiredContractModel[]>();
  private readonly archivingSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly contracts$: Observable<ExpiredContractModel[]> = this.contractsSource.asObservable();
  public loadingContracts = false;
  public archiving: Observable<boolean> = this.archivingSource.asObservable();
  public archivedAmount: number | null;
  public selection: ContractLite;

  public constructor(
    private contractRes: ContractRes,
    private orderRes: OrderRes,
    private toastr: ToastrService,
    private modalRef: BsModalRef
  ) {
    this.getContracts();
  }

  public hide(): void {
    this.modalRef.hide();
  }

  public searchFn(query: string, model: ExpiredContractModel): boolean {
    if (!model || !model.name || !model.number) return false;

    return model.name.includes(query) || model.number.includes(query);
  }

  public selectContract(contract: ContractLite): void {
    this.selection = contract;
  }

  public doArchive(): void {
    this.archivingSource.next(true);

    this.orderRes.archiveByContract({contractId: this.selection.id}).then(r => {
      this.archivedAmount = r.length;
    }).catch(e => {
      this.toastr.error(JSON.stringify(e), 'Viga!');
      this.archivingSource.next(false);
    });
  }

  public getContracts(): void {
    if (this.loadingContracts) return;

    this.loadingContracts = true;
    this.contractRes.getExpiredContracts()
      .then(r => this.contractsSource.next(r))
      .finally(() => this.loadingContracts = false);
  }
}
