import {Component} from '@angular/core';
import {NavbarService} from '@app/core/components/navbar/navbar.service';

@Component({
  selector: 'app-headmenu',
  templateUrl: './headmenu.component.html',
  styleUrls: ['./headmenu.component.scss']
})
export class HeadmenuComponent {

  public contractPermissions: string[] = [];

  constructor(private navbarService: NavbarService) {
    this.contractPermissions = navbarService.contractPermissionList;
  }
}
