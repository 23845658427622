import {ErrorHandler, Injectable} from '@angular/core';
import {LoggerService} from './logger.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(private logger: LoggerService) { }

  handleError(error: Error) {
    console.error(error);
    this.logger.error(error.message, error.stack);
  }
}
