import {Component, OnDestroy} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserNotificationPermissionService} from "@app/core/services/user-notification-permission.service";
import {UserNotificationPermissionsDto} from "@app/core/resource-dto/user-notification-permissions";
import UserNotificationSettingsDto = UserNotificationPermissionsDto.UserNotificationSettingsDto;

@Component({
  templateUrl: './user-notification-permissions-modal.component.html',
})
export class UserNotificationPermissionsModalComponent implements OnDestroy {

  private readonly ngDestroy: Subject<void> = new Subject<void>();
  public currentSettings: UserNotificationSettingsDto = null;

  constructor(
    private service: UserNotificationPermissionService,
    public modalRef: BsModalRef
  ) {
    this.listenToUserNotificationPermissions();
  }

  public saveAndClose(): void {
    this.service.put(this.currentSettings);
    this.modalRef.hide();
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  private listenToUserNotificationPermissions(): void {
    this.service.userNotificationPermissions$.pipe(takeUntil(this.ngDestroy)).subscribe(settings => this.currentSettings = settings);
  }
}
