<div class="modal-container-white modal-sm">
  <div class="modal-header">
    <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" (click)="modalRef.hide()" aria-label="Close">
      <span class="submenu-hover"></span>
      <span class="icon icon_close"></span>
    </button>
  </div>

  <div class="modal-body">
    <div class="work-details-full-width">
      <div class="work-details-1-row">
        <div class="work-details-1-col">
          <label class="work-details-1-label">Nimi</label>
          <div class="w-100">{{user?.name}}</div>
        </div>
      </div>
      <div class="work-details-1-row">
        <div class="work-details-1-col">
          <label class="work-details-1-label">Telefon</label>
          <div class="w-100">{{user?.phone}}</div>
        </div>
      </div>
      <div class="work-details-1-row">
        <div class="work-details-1-col">
          <label class="work-details-1-label">E-post</label>
          <div class="w-100">{{user?.email}}</div>
        </div>
      </div>
      <div class="work-details-1-row" *ngIf="user?.company">
        <div class="work-details-1-col">
          <label class="work-details-1-label">Ettevõte</label>
          <div class="w-100">{{user?.company}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
