<nav class="navbar navbar-details-page">
    <ul>
        <li class="nav-item">
            <a class="nav-link-back"
               [routerLink]="(breadcrumbs$ | async | slice:-2)[0]?.path"
               [queryParams]="queryParams$ | async">
                <span class="icon icon_arrow dark"></span>
                <span class="nav-link-back-label">Tagasi</span>
            </a>
        </li>

        <li class="nav-breadcrumb" *ngFor="let breadcrumb of (breadcrumbs$ | async | slice:0:-1); let i = index">
            <a [routerLink]="breadcrumb.path" type="button"
                    class="btn btn-link">{{breadcrumb.name$ | async}}</a>
            <span class="icon icon_arrow gray"></span>
        </li>

        <li class="nav-breadcrumb">
            <span class="nav-link-current">{{(breadcrumbs$ | async | slice:-1)[0]?.name$ | async}}</span>
        </li>
    </ul>
    <ng-container *ngTemplateOutlet="(actionsComponent$ | async)"></ng-container>
</nav>
