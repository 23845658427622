import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {FileRef} from '../files-section/file-ref.model';
import {distinctUntilChanged} from 'rxjs/operators';
import {ConfigService} from '@app/config/config.service';
import {FormService} from '@app/order/form/form.service';
import {FileRes} from '@app/core/resource/file.resource';
import {ManageService} from '@app/order/manage.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {FileSectionAbstract} from '@app/order/form/files-quest-section/file-section.abstract';

@Component({
  selector: 'form-files-quest-section',
  templateUrl: './files-quest-section.component.html',
  styleUrls: ['./files-quest-section.component.scss']
})
export class FormQuestFilesSectionComponent extends FileSectionAbstract {
  @Input() orderFileType: any;
  @Input() caller: any;
  @ViewChild('uploadInput')
  uploadInput: ElementRef;

  form: FormGroup;
  filesArray: FormArray = this.formService.filesArray;

  private translatedTexts: any;

  public constructor(
    protected config: ConfigService,
    protected formService: FormService,
    protected fileRes: FileRes,
    protected manageService: ManageService,
    protected modalService: BsModalService,
    protected toastr: ToastrService,
    protected translate: TranslateService
  ) {
    super(config, fileRes, modalService, toastr);

    this.form = this.formService.orderForm;

    this.formService.filesArray.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      value.forEach((value: any) => {
        let fileRef = value.fileRef;
        fileRef.isUploaded = true;
        fileRef.uploadProgress = 1;
        fileRef.name = value.fileRef.file.name;
      });
    });

    this.translate.get([
      'Kas oled kindel?',
      'Kas oled kindel, et soovid faili kustutada?',
      'Fail kustutatud!'
    ]).subscribe((result: Array<string>) => {
      this.translatedTexts = result;
    })
  }

  public getTranslatedText(key: string) {
    return this.translatedTexts[key] !== 'undefined' ? this.translatedTexts[key] : key;
  }

  protected removeFileControl(fileControl: FormGroup) {
    this.formService.removeFileControl(fileControl)
  }

  protected addFileControl(fileRef: FileRef) {
    this.formService.addFileControl(fileRef)
  }
}
