import {IResourceActionInner, IResourceResponse, Resource, ResourceHandler, ResourceParams} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '@app/core/services/auth.service';
import * as moment from 'moment';

@ResourceParams({
  pathPrefix: '',
  withCredentials: true
})
export class AuthGuardedResource extends Resource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler);
  }

  protected $handleSuccessResponse(options: IResourceActionInner, resp: IResourceResponse): any {
    this.updateExpirationDate(resp);

    return super.$handleSuccessResponse(options, resp);
  }

  protected $handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse): any {
    // Unauthenticated
    if (resp.status === 401) {
      this.authService.setCurrentUser(null);
      this.authService.redirectToCasLogin();
    } else {
      this.updateExpirationDate(resp);
    }

    return super.$handleErrorResponse(options, resp);
  }

  private updateExpirationDate(response: IResourceResponse): void {
    const expiration = this.getExpiration(response);
    if (!expiration) {
      return;
    }

    this.authService.setExpirationDate(expiration);
  }

  private getExpiration(response: IResourceResponse): Date {
    if (!response?.headers || !response.headers['x-session-expires']?.length) {
      return null;
    }

    const expirationString = response.headers['x-session-expires'][0];
    if (!expirationString) {
      return null;
    }

    const expirationMoment = moment(expirationString);
    if (!expirationMoment.isValid()) {
      return null;
    }

    return expirationMoment.toDate();
  }
}
