import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '@app/core/services/auth.service';
import {ApplianceType} from '@app/core/resource-dto/appliance-type';
import {AuthGuardedResource} from './auth-guarded.resource';

@Injectable({providedIn: 'root'})
export class ApplianceTypeResource extends AuthGuardedResource {

  public constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);

    this.$setUrl(config.getBackendUrl('/appliance-types'));
  }

  @ResourceAction({expectJsonArray: true})
  public getAll: IResourceMethod<void, ApplianceType[]>;
}
