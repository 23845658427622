<div class="modal-container-white">
  <div class="modal-header">
    <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" (click)="close()" aria-label="Sulge">
      <span class="submenu-hover"></span>
      <span class="icon icon_close"></span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          <h6 class="font-weight-bold" *ngIf="message">{{message}}</h6>
          <p class="mt-1" *ngIf="description">{{description}}</p>
        </div>
      </div>
    </div>

    <div class="work-details-full-width" *ngIf="comment" [formGroup]="form">
      <div class="work-details-1-row">
        <div class="work-details-1-col">
          <label class="work-details-1-label">Kommentaar</label>
          <div class="w-100">
          <textarea class="form-control w-100"
                    formControlName="comment"
                    rows="3"
                    [class.is-invalid]="hasErrors(form.get('comment'))">
          </textarea>
            <ng-container *ngIf="hasErrors(form.get('comment'))">
              <small class="invalid-feedback d-block m-0" *ngIf="form.get('comment').errors?.required">
                {{errorMessage}}
              </small>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-outline-primary mr-4">{{cancelLabel}}</button>
    <button [disabled]="loading" (click)="confirm()" type="button" class="btn btn-primary">{{confirmLabel}}</button>
  </div>
</div>
