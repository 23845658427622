<ng-container [formGroup]="form">
  <div class="form-row custom-form-row">
    <div class="col-12">
      <label>{{ 'Failid' | translate }}</label>
      <div class="custom-form-row w-100">
        <div class="file-input w-100 drop-zone ng-invalid" [class.ng-invalid]="filesArray.invalid && filesArray.touched" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
          <span class="icon icon_upload icon-big"></span>
          <h6>{{ 'Lohista fail siia või' | translate }}</h6>
          <button class="btn btn-link">
            <span>{{ 'vali arvutist' | translate }}</span>
            <input (change)="upload($event)" #uploadInput title="file input" multiple="" type="file" name="files[]">
          </button>
        </div>
        <div class="col-12">
          <div class="files-wrapper">
            <ng-container *ngFor="let fileControl of filesArray.controls;let i = index;">
              <div class="file-uploaded show-on-hover" *ngIf="fileControl.value.fileRef.isUploaded && fileControl.value.fileRef.isLoaded && fileControl.value.fileRef.isImage == false">
                <div class="file-img ">
                  <i class="file-icon fas fa-file-pdf text-gray fa-2x"></i>
                </div>
                <div class="file-info">
                  <button class="btn btn-link file-name" (click)="download(fileControl.value.fileRef)">{{fileControl.value.fileRef.name}}</button>
                </div>
                <div class="file-action">
                  <button type="button" class="btn btn-outline-danger btn-icon-only btn-fixed-size p-0" (click)="btnRemoveClicked(i)"><i class="fas fa-trash-alt"></i></button>
                </div>
              </div>

              <div class="file-uploaded show-on-hover" *ngIf="fileControl.value.fileRef.isUploaded && fileControl.value.fileRef.isLoaded && fileControl.value.fileRef.isImage == true">
                <div class="file-img">
                  <img
                    [src]="fileControl.value.fileRef.dataUrl"
                    class="file-icon"
                    style="width: 60px;height: 60px;object-fit: cover;"
                  >
                </div>
                <div class="file-info">
                  <button class="btn btn-link file-name" (click)="btnViewClicked(i)">{{fileControl.value.fileRef.name}}</button>
                </div>
                <div class="file-action">
                  <button type="button" class="btn btn-outline-danger btn-icon-only btn-fixed-size p-0" (click)="btnRemoveClicked(i)"><i class="fas fa-trash-alt"></i></button>
                </div>
              </div>

              <div class="file-uploaded show-on-hover" *ngIf="!fileControl.value.fileRef.isUploaded || !fileControl.value.fileRef.isLoaded">
                <div class="file-img position-relative">
                  <div class="loading-spinner loading-spinner-sm"></div>
                </div>
                <div class="file-info">
                  <button class="btn btn-link file-name"><i>{{ 'Faili laetakse' | translate }}</i></button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
