const transform = {
    1: {transform: 'rotate(0deg)', flipWh: false},
    2: {transform: 'scaleX(-1)', flipWh: false},
    3: {transform: 'rotate(180deg)', flipWh: false},
    4: {transform: 'scaleY(-1)', flipWh: false},
    5: {transform: 'scaleX(-1) rotate(270deg)', flipWh: true},
    6: {transform: 'rotate(90deg)', flipWh: true},
    7: {transform: 'scaleX(-1) rotate(90deg)', flipWh: true},
    8: {transform: 'rotate(270deg)', flipWh: true}
};

function _arrayBufferToBase64(buffer): string {
  const bytes = new Uint8Array( buffer );
  const len = bytes.byteLength;
  let binary = '';

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return window.btoa(binary);
}

export function jpegOrientation(file, callback) {
  const fileReader = new FileReader();
  fileReader.onloadend = () => {
      const base64img = 'data:' + file.type + ';base64,' + _arrayBufferToBase64(fileReader.result);
      const scanner = new DataView(fileReader.result as ArrayBuffer);
      let idx = 0;
      let value = 1; // Non-rotated is the default

      if ((fileReader.result as ArrayBuffer).byteLength < 2 || scanner.getUint16(idx) !== 0xFFD8) {
          // Not a JPEG
          if (callback) {
              callback(base64img, transform[value]);
          }
          return;
      }

      idx += 2;
      let maxBytes = scanner.byteLength;
      let littleEndian = false;
      while (idx < maxBytes - 2) {
          const uint16 = scanner.getUint16(idx, littleEndian);
          idx += 2;
          switch (uint16) {
              case 0xFFE1: // Start of EXIF
                  const endianNess = scanner.getUint16(idx + 8);
                  // II (0x4949) Indicates Intel format - Little Endian
                  // MM (0x4D4D) Indicates Motorola format - Big Endian
                  if (endianNess === 0x4949) {
                      littleEndian = true;
                  }
                  const exifLength = scanner.getUint16(idx, littleEndian);
                  maxBytes = exifLength - idx;
                  idx += 2;
                  break;
              case 0x0112: // Orientation tag
                  // Read the value, its 6 bytes further out
                  // See page 102 at the following URL
                  // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
                  value = scanner.getUint16(idx + 6, littleEndian);
                  maxBytes = 0; // Stop scanning
                  break;
          }
      }
      if (callback) {
          callback(base64img, transform[value]);
      }
  };
  fileReader.readAsArrayBuffer(file);
}
