<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">Koosta teavitus</h4>
  <button type="button" class="close pull-right" (click)="modalRef.hide()" aria-label="Close">
    <span class="submenu-hover"></span>
    <span class="icon icon_close"></span>
  </button>
</div>

<div class="modal-body" [formGroup]="form">
  <div class="work-details-full-width">
    <div class="work-details-1-row">
      <div class="work-details-1-col">
        <label class="work-details-1-label">Kuva teade</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [id]="id + '-checkbox'" formControlName="enabled" [class.is-invalid]="isInvalid('enabled')">
        </div>
        <small class="invalid-feedback" [hidden]="!form.get('enabled').errors?.required">Kohustuslik väli</small>
      </div>
    </div>

    <div class="work-details-1-row">
      <div class="work-details-1-col">
        <label class="work-details-1-label">Teavitus</label>
        <div class="w-100">
          <textarea class="form-control w-100" rows="3" formControlName="detail" [class.is-invalid]="isInvalid('detail')"></textarea>
          <small class="invalid-feedback" [hidden]="!form.get('detail').errors?.required">Kohustuslik väli</small>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer footer-padding">
  <button (click)="modalRef.hide()" type="button" class="btn btn-outline-primary mr-4">Katkesta</button>
  <button (click)="delete()" type="button" class="btn btn-outline-danger mr-4">Kustuta</button>
  <button (click)="submit()" type="button" class="btn btn-outline-primary">Salvesta</button>
</div>
