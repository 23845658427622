import {Component, Input} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {UserDetailModalComponent} from '../user-detail-modal/user-detail-modal.component';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html'
})
export class UserLinkComponent {

  @Input()
  public title: string;
  @Input()
  public user: {name: string, phone?: string, email?: string};
  @Input()
  public classes = ['p-0'];

  constructor(private modalService: BsModalService) { }

  public showDetail(): void {
    this.modalService.show(UserDetailModalComponent,
      {
        backdrop: true,
        ignoreBackdropClick: false,
        class: 'double-modal',
        initialState: {title: this.title, user: this.user}
      });
  }
}
