<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">
    <span class="icon icon_error icon-sm icon-warning mr-2"></span>
    {{isDisabled ? 'Session on aegunud' : 'Sessioon aegub'}}
  </h4>
  <button class="close pull-right" type="button" aria-label="Sulge" [disabled]="isDisabled" (click)="hide()">
    <span class="submenu-hover"></span>
    <span class="icon icon_close"></span>
  </button>
</div>

<div class="modal-body px-3">
  Teie sessioon on aegumas ja teid logitakse välja. Kas soovid jätkata?
</div>

<div class="modal-footer">
  <button class="btn btn-outline-primary" type="button" (click)="logout()">
    Logi välja
  </button>
  <button class="btn btn-primary" type="button" (click)="extendSession()">
    {{isDisabled ? 'Sessioon on aegunud' : ('Jätka sessiooni ' + timeRemaining + 's')}}
  </button>
</div>
