<ng-container [formGroup]="orderForm">

    <div class="form-row custom-form-row mt-0">

        <div class="col-12">

            <label id="building-label">Hoone</label>

            <div class="w-100">

                <ng-select class="custom w-100 no-footer"
                           [ngClass]="{'mobile-select' : isMobileView$ | async}"
                           formControlName="building"
                           bindLabel="text"
                           placeholder="Otsi objekti"
                           notFoundText="Hooneid ei leitud"
                           typeToSearchText="Kirjuta, et otsida&hellip;"
                           labelForId="building-label"
                           [clearable]="true"
                           [searchable]="true"
                           [class.is-invalid]="formErrors.building"
                           [items]="options$ | async"
                           [typeahead]="querySource">

                    <ng-template ng-header-tmp *ngxPermissionsOnly="['building.access.my-buildings.manager-buildings']">

                        <div class="custom-control custom-checkbox pt-1 pb-1">

                            <input id="my-buildings-input"
                                   class="custom-control-input"
                                   type="checkbox"
                                   [formControl]="myBuildingsFilter">

                            <label class="custom-control-label" for="my-buildings-input">
                                Minu hooned
                            </label>

                        </div>

                    </ng-template>

                </ng-select>

                <small *ngIf="formErrors?.building?.required" class="invalid-feedback d-block">
                    {{formErrors?.building?.required}}
                </small>

            </div>

        </div>

    </div>

    <div class="form-row custom-form-row mt-0" [hidden]="!orderForm.get('building').value">
        <div class="col-12">
            <label>Haldur</label>
            <app-user-link title="Halduri info" [user]="propertyManager"></app-user-link>
        </div>
    </div>

    <div class="form-row custom-form-row mt-0">

        <div class="col-12">

            <label for="location-input">Asukoht</label>

            <div class="w-100">

                <input id="location-input"
                       class="form-control"
                       formControlName="location"
                       placeholder="Asukoha täpsustus hoones"
                       [class.is-invalid]="formErrors.location">

            </div>

        </div>

    </div>

</ng-container>
