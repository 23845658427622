import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from "@app/core/services/auth.service";
import {BuildingDto} from "@app/core/resource-dto/building";

@Injectable({providedIn: 'root'})
export class BuildingRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/buildings'));
    }

    @ResourceAction({
        path: '/{!buildingId}'
    })
    get: IResourceMethod<{buildingId: any}, BuildingDto.Building>;

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<BuildingDto.QueryInput, BuildingDto.QueryOutput>;

}
