import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import Classifier = ClassifierDto.Classifier;
import ClassifierValue = ClassifierDto.ClassifierValue;
import {map, tap} from 'rxjs/operators';
import {ClassifierRes} from '../resource/classifier.resource';
import {ClassifierDto} from '../resource-dto/classifier';

@Injectable({providedIn: 'root'})
export class ClassifierService {

    private readonly classifiersLoadingSubject = new BehaviorSubject<boolean>(true);
    public readonly classifiersLoading$ = this.classifiersLoadingSubject.asObservable();

    private readonly classifierSource = new ReplaySubject<Map<string, Classifier>>(1);
    public readonly classifierSource$ = this.classifierSource.asObservable();

    private classifierMap: Map<string, any>;

    public constructor(private classifierRes: ClassifierRes) {}

    public init() {
        this.loadClassifiers();
    }

    public getClassifierValueMapObservable(code: string): Observable<Map<string, string>> {
        return this.classifierSource$.pipe(map(classifiers => {
            const values = classifiers.get(code)?.values || [];

            return new Map(values.map(x => [x.code, x.name]));
        }));
    }

    public getClassifierValueObservable(code: string): Observable<ClassifierValue[]> {
        return this.classifierSource$.pipe(map(x => x.get(code)?.values || []));
    }

    public getClassifierValueByCodeObservable(classifierCode: string, valueCode: string): Observable<ClassifierValue | undefined> {
        return this.classifierSource$
            .pipe(map(x => (x.get(classifierCode)?.values || []).find(y => y.code === valueCode)));
    }

    public getClassifierValueObservableSorted(code: string): Observable<ClassifierValue[]> {
        return this.getClassifierValueObservable(code).pipe(
            tap(results => results.sort((a, b) => a.orderNum > b.orderNum ? 1 : -1))
        );
    }

    public getClassifierByCode(code: string): Classifier {
        return this.classifierMap ? this.classifierMap.get(code) : null;
    }

    public getClassifierValueByCode(classifierCode: string, valueCode: string): ClassifierValue {
        const classifier = this.getClassifierByCode(classifierCode);

        return classifier ? classifier.values.find(c => c.code === valueCode) : null;
    }

    private loadClassifiers(): void {
        this.classifierRes.query({}).then(output => {
            this.updateClassifierMap(output.classifiers);
            this.updateClassifierSource();
            this.classifiersLoadingSubject.next(false);
        });
    }

    private updateClassifierMap(classifiers: Classifier[]): void {
        this.classifierMap = new Map<string, Classifier>(classifiers.map(c => [c.code, c] as [string, Classifier]));
    }

    private updateClassifierSource(): void {
        this.classifierSource.next(this.classifierMap);
    }
}
