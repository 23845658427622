<div class="modal-header">
  <h4 id="dialog-sizes-name1" class="modal-title pull-left">Sünkimine</h4>
  <button type="button" class="close pull-right" (click)="modalRef.hide()" aria-label="Close">
    <span class="submenu-hover"></span>
    <span class="icon icon_close"></span>
  </button>
</div>

<div class="modal-body">
  <app-property-sync></app-property-sync>
  <hr />
  <app-userregistry-sync></app-userregistry-sync>
  <hr />
  <app-contract-sync></app-contract-sync>
  <hr />
  <app-cache-clear></app-cache-clear>
</div>
