import {Inject, Injectable, InjectionToken} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {CoreConfig} from '@app/core/core-config.model';

export const MOMENT_NGB_DATE_FORMAT = new InjectionToken<CoreConfig>('Format for MomentNgbDateParserFormatter');

@Injectable()
export class MomentNgbDateParserFormatter extends NgbDateParserFormatter {

  constructor(@Inject(MOMENT_NGB_DATE_FORMAT) private momentFormat: string) {
    super();
  }

  public format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }

    const d = moment({ year: date.year, month: date.month - 1, date: date.day });

    return d.isValid() ? d.format(this.momentFormat) : '';
  }

  public parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    const d = moment(value, this.momentFormat);

    return d.isValid() ? { year: d.year(), month: d.month() + 1, day: d.date() } : null;
  }
}
